/* Contenedor principal del panel */
.panel-admin-container {
    padding: 20px;
    color: #f0f0f0; /* Texto claro */
    display: flex;
    flex-direction: column; /* Asegura que los elementos se dispongan verticalmente */
    justify-content: center; /* Centra verticalmente */
    align-items: center; /* Centra horizontalmente */
    overflow: hidden; /* Elimina el scroll dentro del contenedor */
}

/* Título principal */
.panel-admin-title {
    font-size: 2.5rem;
    color: #75FACC; /* Verde brillante */
    text-align: center;
    margin-bottom: 10px;
}

/* Subtítulo */
.panel-admin-subtitle {
    font-size: 1.2rem;
    color: #ddd;
    text-align: center;
    margin-bottom: 30px;
}

/* Contenedor para las tarjetas */
.panel-admin-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    width: 80%; /* Ajusta el ancho al contenedor principal */
    margin-top: 10px;
}

/* Tarjetas individuales */
.panel-admin-card {
    background-color: #292929; /* Fondo más claro que el fondo principal */
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s, box-shadow 0.3s;
}

/* Estilo del título de las tarjetas */
.panel-admin-card h3 {
    font-size: 1.5rem;
    color: #645ad3c7; /* Color llamativo */
    margin-bottom: 10px;
}

/* Estilo del texto en las tarjetas */
.panel-admin-card p {
    font-size: 1rem;
    color: #ccc;
}

/* Hover en las tarjetas */
.panel-admin-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.5);
}

/* Responsividad */
@media (max-width: 768px) {
    .panel-admin-content {
        flex-direction: column;
        align-items: center;
    }
}
