/* Estilo para el título con las clases únicas */
.h1boost-overwatch-admin {
    color: white;
    text-align: center;
    font-size: 2.5rem; /* Tamaño de fuente ajustado */
    margin-bottom: 20px; /* Espacio debajo del título */
}

/* Estilo para la tabla */
.boosts-table-overwatch-admin {
    width: 100%; /* Ancho de la tabla */
    border-collapse: collapse;
    margin: 20px auto; /* Centrado horizontal y espacio superior e inferior */
    background-color: #1f1f1f; /* Fondo oscuro para la tabla */
    color: white; /* Color de texto blanco */
    font-family: Arial, sans-serif; /* Fuente más clara */
    max-width: 1200px; /* Ancho máximo para la tabla */
}


.boosts-table-overwatch-admin th,
.boosts-table-overwatch-admin td {
    padding: 12px;
    text-align: left;
    border: 1px solid #444; /* Borde oscuro */
}

.boosts-table-overwatch-admin th {
    background-color: #333; /* Fondo gris oscuro para los encabezados */
    font-weight: bold; /* Negrita en los encabezados */
}

.boosts-table-overwatch-admin tr:nth-child(even) {
    background-color: #292929; /* Color de fondo alternativo para filas */
}

.boosts-table-overwatch-admin td {
    font-size: 1rem; /* Ajuste de tamaño de fuente para las celdas */
}

/* Ajuste de los bordes para un diseño más limpio */
.boosts-table-overwatch-admin td,
.boosts-table-overwatch-admin th {
    border: 1px solid #555; /* Color más claro para el borde */
}

/* Estilos para el modal */
.modal-overlay-overwatch-admin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Asegúrate de que el modal esté sobre otros elementos */
}

.modal-overwatch-admin {
    background-color: #2c2c2c;
    color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    text-align: center;
}

.close-button-overwatch-admin {
    background-color: #f44336; /* Color rojo para el botón de cerrar */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}

.close-button-overwatch-admin:hover {
    background-color: #d32f2f; /* Color rojo más oscuro al pasar el mouse */
}

/* Estilo para el botón de Detalles */
.details-button-overwatch-admin {
    background-color: #51cda2; /* Color verde para el botón */
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
}

.details-button-overwatch-admin:hover {
    background-color: #399575; /* Color verde más oscuro al pasar el mouse */
}

.filters-overwatch-admin {
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    gap: 20px; /* Separación entre cada filtro */
    margin: 20px 0; /* Espaciado superior e inferior */
}

.filters-overwatch-admin label {
    color: white; /* Letras blancas */
    font-size: 16px; /* Tamaño del texto */
    cursor: pointer; /* Cambia el cursor al pasar sobre la etiqueta */
    display: flex;
    align-items: center; /* Alinear texto y checkbox verticalmente */
    gap: 5px; /* Separación entre el checkbox y el texto */
}

.filters-overwatch-admin input[type="checkbox"] {
    transform: scale(1.2); /* Aumentar ligeramente el tamaño del checkbox */
    accent-color: white; /* Cambiar el color del checkbox a blanco */
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    gap: 10px; /* Espaciado entre las flechas */
}

.arrow-button {
    padding: 10px 15px;
    border: 1px solid #ddd;
    background-color: white;
    color: black;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.2s;
}

.arrow-button:hover {
    background-color: #007bff;
    color: white;
    transform: scale(1.1); /* Pequeño efecto de zoom */
}

.arrow-button:disabled {
    background-color: #f0f0f0;
    color: #aaa;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    /* Contenedor principal */
    .main-container {
        padding: 10px; /* Reduce el padding para maximizar espacio */
    }

    /* Título principal */
    .h1boost-overwatch-admin {
        font-size: 1.5rem; /* Ajusta el tamaño del título */
        text-align: center;
        margin-bottom: 15px; /* Añade separación inferior */
    }

    /* Filtros */
    .filters-overwatch-admin {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; /* Centra los filtros */
        gap: 10px; /* Espaciado entre los filtros */
        padding: 10px 0;
    }

    .filters-overwatch-admin label {
        font-size: 0.9rem; /* Reduce el tamaño del texto */
    }

    /* Tabla */
    .boosts-table-overwatch-admin {
        width: 100%; /* Asegura que ocupe todo el ancho disponible */
        overflow-x: auto; /* Permite scroll horizontal si es necesario */
        display: block; /* Convierte la tabla en un bloque */
    }

    .boosts-table-overwatch-admin th,
    .boosts-table-overwatch-admin td {
        font-size: 0.9rem; /* Reduce el tamaño del texto */
        padding: 8px; /* Reduce el padding */
        text-align: center; /* Centra el contenido */
        white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    }

    .boosts-table-overwatch-admin tbody tr {
        display: flex;
        flex-direction: column; /* Organiza las filas en columnas */
        align-items: flex-start; /* Alinea el contenido a la izquierda */
        border-bottom: 1px solid #ccc; /* Añade separación entre filas */
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .boosts-table-overwatch-admin tbody tr td:before {
        content: attr(data-label); /* Usa el atributo data-label para encabezados en móvil */
        font-weight: bold;
        margin-right: 10px;
        text-transform: uppercase;
    }

    .boosts-table-overwatch-admin thead {
        display: none; /* Oculta los encabezados de la tabla en móvil */
    }

    /* Botones */
    .details-button-overwatch-admin,
    .arrow-button {
        font-size: 0.8rem; /* Reduce el tamaño del texto en botones */
        padding: 10px;
        margin: 5px;
        width: 100%; /* Ajusta el ancho al 100% */
        text-align: center;
    }

    /* Paginación */
    .pagination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }

    .pagination button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    /* Modal */
    .modal-overlay-overwatch-admin {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1000;
    }

    .modal-overwatch-admin {
        width: 90%; /* Ajusta el tamaño al 90% del ancho de la pantalla */
        max-height: 80%; /* Limita la altura máxima */
        overflow-y: auto; /* Añade scroll si el contenido excede la altura */
        padding: 20px;
        border-radius: 10px;
        text-align: center;
    }

    .modal-overwatch-admin h2 {
        font-size: 1.2rem; /* Ajusta el tamaño del título del modal */
    }

    .modal-overwatch-admin textarea,
    .modal-overwatch-admin input,
    .modal-overwatch-admin select {
        width: 100%; /* Asegura que los inputs ocupen todo el ancho */
        margin-bottom: 15px; /* Añade separación entre campos */
        padding: 10px;
        font-size: 0.9rem; /* Reduce el tamaño del texto */
    }

    .modal-overwatch-admin button {
        font-size: 0.9rem;
        padding: 10px;
        margin: 5px;
        width: 100%; /* Botones adaptados al ancho completo */
    }
}

@media (max-width: 768px) {
    /* Tabla */
    .boosts-table-overwatch-admin {
        width: 100%; /* Asegura que ocupe todo el ancho disponible */
        overflow-x: auto; /* Permite scroll horizontal si es necesario */
        display: block; /* Convierte la tabla en un bloque */
        text-align: center; /* Centra todo el texto en la tabla */
    }

    .boosts-table-overwatch-admin tbody {
        display: flex;
        flex-direction: column; /* Organiza las filas en columnas */
        gap: 10px; /* Añade espacio entre las filas */
        align-items: center; /* Centra todas las filas */
    }

    .boosts-table-overwatch-admin tbody tr {
        display: flex;
        flex-direction: column; /* Convierte las filas en columnas */
        align-items: center; /* Centra el contenido de cada fila */
        border-radius: 5px; /* Bordes redondeados para filas */
        padding: 10px; /* Añade espacio interno */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra ligera */
        width: 90%; /* Asegura que las filas no ocupen toda la pantalla */
    }

    .boosts-table-overwatch-admin tbody tr td {
        display: flex;
        justify-content: center; /* Centra el texto horizontalmente */
        align-items: center; /* Centra el texto verticalmente */
        text-align: center; /* Centra el contenido */
        padding: 8px 0; /* Añade espacio interno */
        font-size: 0.9rem; /* Tamaño de texto reducido */
        width: 100%; /* Asegura que las celdas ocupen todo el ancho */
    }

    .boosts-table-overwatch-admin tbody tr td:before {
        content: attr(data-label); /* Usa el atributo data-label como encabezado */
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px; /* Espacio entre el encabezado y el contenido */
        text-align: center; /* Centra el encabezado */
        width: 100%; /* Asegura que el encabezado ocupe toda la celda */
    }

    .boosts-table-overwatch-admin thead {
        display: none; /* Oculta los encabezados en dispositivos móviles */
    }

    /* Botones */
    .details-button-overwatch-admin,
    .arrow-button {
        font-size: 0.8rem; /* Reduce el tamaño del texto en botones */
        padding: 10px;
        margin: 5px;
        text-align: center; /* Centra el texto de los botones */
        display: inline-block; /* Asegura que los botones sean visibles */
    }

    /* Paginación */
    .pagination {
        display: flex;
        justify-content: center; /* Centra los botones de paginación */
        align-items: center;
        padding: 10px 0;
    }

    .pagination button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }
}

.boosts-table-overwatch-admin {
    width: 80%;
    margin: 20px auto;
    border-collapse: collapse;
    background-color: #1e1e2f; /* Fondo oscuro */
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    color: #ffffff; /* Texto blanco */
}

.boosts-table-overwatch-admin thead {
    background-color: #645AD3; /* Morado suave para el encabezado */
}

.boosts-table-overwatch-admin th, 
.boosts-table-overwatch-admin td {
    padding: 15px 20px;
    text-align: left;
    border-bottom: 1px solid #343450;
    font-size: 0.9rem;
}

.boosts-table-overwatch-admin th {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    color: #ffffff; /* Texto blanco */
}

.boosts-table-overwatch-admin tbody tr {
    background-color: #645ad3c7; /* Alternar filas con un tono más claro */
}

.boosts-table-overwatch-admin tbody tr:nth-child(even) {
    background-color: #645ad3c7; /* Alternar filas con un tono más claro */
}


.boosts-table-overwatch-admin td {
    color: #ffffff; /* Texto blanco */
}

.boosts-table-overwatch-admin td button {
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
    color: #ffffff; /* Texto blanco */
}

.boosts-table-overwatch-admin td button:first-child {
    background-color: #75FACC; /* Botón verde agua */
    padding: 8px 6px;
}

.boosts-table-overwatch-admin td button:first-child:hover {
    background-color: #59D3B0; /* Hover en verde más oscuro */
}

.boosts-table-overwatch-admin td button:last-child {
    background-color: #E74C3C; /* Botón rojo */
    margin-left: 5px;
    padding: 8px 8px;
}

.boosts-table-overwatch-admin td button:last-child:hover {
    background-color: #C0392B; /* Hover en rojo más oscuro */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .boosts-table-overwatch-admin {
        width: 95%;
    }

    .boosts-table-overwatch-admin th, 
    .boosts-table-overwatch-admin td {
        padding: 10px 12px;
    }
}

