.contenedor-principal-cliente {
    padding: 20px;
    max-width: 1400px; /* Incrementar el ancho máximo */
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.titulo-cliente {
    text-align: center;
    font-size: 2.5rem; /* Tamaño más grande */
    color: #ffffff; /* Título en blanco */
    margin-bottom: 30px; /* Espaciado más amplio */
}

.mensaje-cargando-cliente,
.mensaje-error-cliente {
    text-align: center;
    font-size: 1.2rem;
    color: #ff5555; /* Color de error */
    margin-top: 20px;
}

.tabla-clientes {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #0f3460; /* Fondo de la tabla */
    border-radius: 8px;
    overflow: hidden;
    table-layout: fixed; /* Ajustar ancho uniforme */
}

.tabla-clientes th,
.tabla-clientes td {
    padding: 15px; /* Aumentar el espacio dentro de las celdas */
    text-align: left;
    border: 1px solid #16213e; /* Bordes suaves */
    word-wrap: break-word; /* Ajustar texto largo */
    background-color: none;
}

.encabezado-cliente {
    background-color: #645ad3c7; /* Fondo de los encabezados */
    color: #ffffff; /* Texto oscuro para contraste */
    font-weight: bold;
    font-size: 1.2rem; /* Hacer el texto un poco más grande */
}

.fila-cliente:nth-child(even) {
    background-color: #1a1a2e; /* Alternancia en filas */
}

.fila-cliente:hover {
    background-color: #16213e; /* Resaltado al pasar el mouse */
}

.celda-cliente {
    color: #ffffff; /* Texto en blanco */
}

/* Botón de recarga o acciones adicionales */
.boton-cliente {
    display: inline-block;
    background-color: #51cda2; /* Botón principal */
    color: #030315; /* Texto oscuro */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.boton-cliente:hover {
    background-color: #3fa286; /* Cambio al pasar el mouse */
}


.contenedor-principal-cliente {
    margin-top: 30px;
    margin-bottom: 30px;
}

/* Paginación */
.paginacion-cliente {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.flecha-cliente {
    background-color: #51cda2;
    color: #030315;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.flecha-cliente:hover {
    background-color: #3fa286;
}

.flecha-cliente:disabled {
    background-color: #7fa6a6;
    cursor: not-allowed;
}

.pagina-actual-cliente {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
}

.encabezado-cliente{
    background-color: #645ad3c7 !important;
}