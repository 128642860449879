/* src/styles/cuentadetalle.css */
.container {
    display: flex;
    flex-direction: column; /* Asegura que los hijos se apilen verticalmente */
    align-items: center; /* Centra el contenido horizontalmente */
}

.principal-info {
    display: flex;
    align-items: flex-start; /* Alinea el contenido al inicio */
    max-width: 1200px; /* Ancho máximo del contenedor */
    margin: 0 auto; /* Centra el contenedor */
    color: #75FACC;
    margin-top: 30px;
    margin-bottom: 10px;
}

.image-container {
    flex: 1; /* La imagen ocupará un espacio flexible */
    padding-right: 20px; /* Espaciado a la derecha de la imagen */
}

.image-container img {
    max-width: 100%; /* Asegura que la imagen no desborde el contenedor */
    border-radius: 10px; /* Bordes redondeados en la imagen */
}

.details {
    flex: 2; /* Los detalles ocuparán el doble de espacio que la imagen */
}

h2 {
    margin: 0 0 10px 0; /* Margen inferior para el título */
    font-size: 2rem; /* Tamaño de fuente del título */
    color: #ffffff; /* Color del título */
}

.price {
    font-size: 1.5rem; /* Tamaño de fuente del precio */
    color: #ffffff; /* Color azul para el precio */
    margin: 10px 0; /* Margen superior e inferior */
}

/* Unique button for adding to cart */
.add-to-cart-button {
    display: inline-block;
    padding: 10px 80px; /* Increased side padding for a wider button */
    background-color: #75FACC; /* Green color */
    color: #03031F;
    border: none;
    border-radius: 20px; /* Increased radius for a more circular appearance */
    font-size: 1.1rem;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    text-decoration: none;
    font-weight: 600;
}


.add-to-cart-button:hover {
    background-color: #60d0a9; /* Darker green on hover */
}

/* Additional button examples */
.custom-button {
    padding: 10px 15px;
    background-color: #007bff; /* Blue color */
    border-radius: 5px;
    color: white;
}

.custom-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}


.table-container {
    display: flex; /* Usa flexbox para mostrar las tablas una al lado de la otra */
    justify-content: space-between; /* Espacia las tablas */
    margin-top: 20px;
    overflow-x: auto; /* Habilita el desplazamiento horizontal si es necesario */
    width: 60%;
    margin-bottom: 30px;
}

.table-section {
    flex: 1; /* Permite que las tablas se expandan igual */
    margin: 0 10px; /* Agrega margen lateral entre las tablas */
}

/* Centra el texto en la segunda columna de las tablas */
td:nth-child(2) {
    text-align: center; /* Centra el texto en la segunda columna */
}

/* Cambia el color de fondo de la primera columna de las tablas */
th:first-child, 
td:first-child {
    background-color: rgba(0, 123, 255, 0.1); /* Color de fondo para la primera columna */
    max-width: 150px; /* Ancho máximo para la primera columna */
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    overflow: hidden; /* Oculta el texto que desborde */
    text-overflow: ellipsis; /* Añade '...' si el texto es demasiado largo */
}

/* Ajustes generales para la tabla */
table {
    width: 100%; /* Asegúrate de que cada tabla ocupe el ancho completo de su contenedor */
    border-collapse: collapse; /* Mantiene las celdas unidas */
}

th, td {
    padding: 10px; /* Ajusta el espaciado interno de las celdas */
    text-align: left; /* Alineación a la izquierda del texto por defecto */
    border: 2px solid rgba(0, 0, 0, 0.3); /* Borde más grueso y con menos opacidad */
    background-color: transparent; /* Sin color de fondo para las celdas */
    color: #75FACC; /* Color del texto de las celdas */
}

th {
    font-weight: bold; /* Negrita para los encabezados */
    background-color: #007BFF; /* Color azul oscuro para el fondo del encabezado */
    color: white; /* Color blanco para el texto del encabezado */
}

tr:nth-child(even) {
    background-color: transparent; /* Sin color de fondo para filas pares */
}

tr:hover {
    background-color: rgba(0, 123, 255, 0.1); /* Azul claro en hover para mejor visibilidad */
}

.table-title {
    text-align: center; /* Centra el texto del título */
    margin: 20px 0; /* Espacio alrededor del título */
    font-size: 1.5rem; /* Tamaño del texto */
    font-weight: bold; /* Peso del texto */
    color: #75FACC;
}


.info-title {
    font-weight: bold; /* Bold for the info titles */
    color: #007BFF; /* Blue color for the info titles for emphasis */
}

.platform {
    font-size: 1.2em; /* Larger font size for the platform */
    margin: 10px 0; /* Space above and below */
    font-weight: bold; /* Bold */
    color: #007BFF; /* Blue color for platform */
}

.platform.pc {
    color: rgb(104, 205, 221); /* Color for PC */
}

.platform.consola {
    color: rgb(104, 205, 221); /* Color for Console */
}


.image-container {
    text-align: center; /* Center content in the image container */
}

.gallery-container {
    margin-top: 10px; /* Space between the image and the link */
}

.gallery-link {
    color: rgb(104, 205, 221); /* Link color */
    text-decoration: underline; /* Underline for a clickable appearance */
    cursor: pointer; /* Cursor change on hover */
}

.gallery-link:hover {
    color: rgb(62, 143, 156); /* Darker shade on hover */
}

.terms-of-service {
    margin-top: 20px; /* Add some spacing above */
    font-size: 1rem; /* Slightly smaller font size */
    text-align: justify; /* Justify the text */
    color: #b5b0b0; /* A lighter color for the text */
    margin-top: 30px;
}

.terms-of-service b{
    font-size: 1.1rem;
}


/* cuentadetalle.css */

/* Estilos para mobile */
@media (max-width: 480px) {

    .principal-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .image-container {
        width: 100%;
        text-align: center;
    }

    .image-container img {
        width: 100%;
        max-width: 300px; /* Limita el tamaño máximo de la imagen */
        height: auto;
        margin-bottom: 10px;
    }

    .gallery-container {
        margin-top: 10px;
    }

    .gallery-link {
        font-size: 14px;
        color: #007BFF;
        text-decoration: none;
        display: inline-block;
    }

    .details {
        width: 100%;
        text-align: center;
        padding: 0 10px;
    }

    .details h2 {
        font-size: 20px;
        font-weight: bold;
        margin-top: 15px;
    }

    .platform {
        font-size: 14px;
        color: #555;
        margin: 5px 0;
    }

    .price {
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        margin: 10px 0;
    }

    .add-to-cart-button {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 15px;
    }

    .add-to-cart-button:disabled {
        background-color: #ddd;
        cursor: not-allowed;
    }

    .terms-of-service {
        font-size: 12px;
        color: #777;
        margin-top: 20px;
        line-height: 1.5;
    }
}

/* Estilos para mobile */
@media (max-width: 480px) {
    .table-container {
        display: flex;
        flex-direction: column; /* Apila las tablas verticalmente */
        align-items: center;
        padding: 10px; /* Reducir padding para más espacio */
    }

    .table-section {
        width: 100%; /* Hace que las tablas ocupen el 100% del ancho disponible */
        margin-bottom: 20px; /* Agrega un margen entre las tablas */
    }

    table {
        width: 100%; /* Asegura que la tabla ocupe todo el ancho disponible */
        border-collapse: collapse; /* Colapsa los bordes de las celdas */
    }

    th, td {
        padding: 8px 5px; /* Reducir el padding de las celdas para más espacio */
        text-align: left;
        font-size: 13px; /* Reducir el tamaño de la fuente para pantallas pequeñas */
    }

    .info-title {
        font-weight: bold;
        color: #333;
        width: 40%; /* Reducir el ancho de los títulos de las celdas */
        white-space: nowrap; /* Evitar que el texto se divida en varias líneas */
    }

    td {
        word-wrap: break-word; /* Permite que el texto largo se divida en varias líneas si es necesario */
    }

    /* Ajusta el título de la tabla para que se vea centrado y no ocupe mucho espacio */
    .table-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
    }
}