/* Contenedor principal */
.chat-container {
    width: 80%;
    margin: 30px auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    height: 60vh; /* Establecer una altura fija */
}

/* Encabezado del chat */
.chat-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #645ad3c7;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.chat-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.chat-username {
    font-size: 18px;
    font-weight: bold;
    color: white;
}

/* Cuerpo del chat */
.chat-body {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

/* Estilo para los mensajes */
.chat-message {
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    max-width: 70%;
}

.user-message {
    align-self: flex-end;
    background-color: #645ad3c7;
    color: white;
    border-radius: 8px;
    padding: 10px;
}

.admin-message {
    align-self: flex-start;
    background-color: #e0e0e0;
    color: black;
    border-radius: 8px;
    padding: 10px;
}

.chat-message-content {
    font-size: 14px;
    margin-bottom: 5px;
}

.chat-message-timestamp {
    font-size: 12px;
    color: #000000;
    text-align: right;
}

/* Entrada de mensaje */
.chat-input {
    display: flex;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 0 0 8px 8px; /* Rounds the bottom corners */
    box-sizing: border-box; /* Prevents content from exceeding the chat window */
    margin-left: -1px;
    margin-right: -1px;
}


.chat-input input {
    flex: 1;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.chat-input button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #645ad3c7;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-input button:hover {
    background-color: #645ad3c7;
}

.no-messages {
    text-align: center;
    color: #888;
    font-size: 16px;
    margin-top: 50px;
}


/* Estilo para cada conversación en la lista */
.conversation-item {
    padding: 12px;
    margin-bottom: 10px;
    background-color: #f2f2f2;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

/* Cambio de color al pasar el mouse sobre una conversación */
.conversation-item:hover {
    background-color: #e0e0e0;
}

.conversation-item {
    transition: background-color 0.2s ease;
}
.conversation-item:hover, .conversation-item.selected {
    background-color: #dedede; /* Light background on hover */
}


/* Estilo para la conversación seleccionada */
.selected-conversation {
    background-color: #645ad3c7;
    color: white;
}

/* Contenedor de los mensajes del chat (derecha) */
.chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    height: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Para el encabezado de la ventana de chat */
.chat-window-header {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
    font-size: 1.2em;
}

.chat-container2 {
    width: 80%;
    margin: 30px auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    height: 65vh; /* Establecer una altura fija */
    display: flex;
    flex-direction: row; /* Mantén la disposición en filas */
}

.conversations-list {
    width: 25%; /* El 25% de ancho para las conversaciones */
    padding: 10px;
    background-color: #f5f5f5; /* Color de fondo de las conversaciones */
    border-right: 2px solid #ddd;
}

.chat-body-container {
    flex: 1; /* El chat ocupa el espacio restante */
    display: flex;
    flex-direction: column; /* Los mensajes estarán en una columna */
    height: 100%; /* Aseguramos que ocupe toda la altura disponible */
    justify-content: space-between; /* Asegura que el input se mantenga en la parte inferior */
}

.conversation-item.selected {
    background-color: #645ad3; /* Selected background */
    color: white; /* Text color */
    font-weight: bold;
}

.chat-input button {
    background-color: #645ad3;
    color: #fff;
    border-radius: 8px;
    padding: 8px 16px;
}

.conversations-list {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 20px;
    background-color: #fff;
    max-width: 300px;
}




.search-bar-container {
    display: flex;
    flex-direction: column; /* Organiza los elementos en una columna */
    width: 100%;
}



.search-results {
    max-height: 300px; /* Ajusta según el espacio disponible o la cantidad de resultados */
    overflow-y: auto; /* Muestra una barra de desplazamiento si los resultados son muchos */
    background-color: #fff; /* Fondo blanco para los resultados */
    border: 1px solid #ddd; /* Borde para hacer que los resultados sean visibles */
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Agrega sombra a los resultados */
    padding: 5px;
    width: 100%; /* Asegura que los resultados ocupen todo el ancho disponible */
    margin-top: 5px; /* Espacio entre la barra de búsqueda y los resultados */
}

.user-item {
    padding: 8px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #ddd; /* Borde inferior para separar los elementos */
}

.user-item:hover {
    background-color: #e9e9e9; /* Cambio de color al pasar el cursor sobre un usuario */
}


.search-bar {
    position: relative; /* Para el icono de búsqueda */
    width: 100%; /* Ajusta el tamaño del contenedor */
    max-width: 400px; /* Define un máximo de tamaño para la barra */
    margin: 2px 2px; /* Centra la barra en su contenedor */
}

.search-bar input {

    padding: 12px 40px 12px 16px; /* Espaciado alrededor del texto, espacio extra a la izquierda para el icono */
    font-size: 16px;
    border: 2px solid #ddd; /* Borde sutil */
    border-radius: 25px; /* Bordes redondeados */
    background-color: #f9f9f9; /* Fondo suave */
    color: #333; /* Texto en color oscuro */
    outline: none; /* Elimina el borde azul al hacer foco */
    transition: all 0.3s ease; /* Transición suave para efectos al hacer foco */
}

.search-bar input:focus {
    border-color: #007BFF; /* Cambio de color del borde al hacer foco */
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* Sombra suave */
}

.search-bar input::placeholder {
    color: #888; /* Color del texto del placeholder */
    font-style: italic; /* Estilo itálico para el texto de sugerencia */
}

/* Icono de búsqueda */
.search-bar .search-icon {
    position: absolute;
    top: 50%;
    left: 12px; /* Espacio entre el borde y el icono */
    transform: translateY(-50%); /* Centra verticalmente el icono */
    color: #007BFF; /* Color del icono */
    font-size: 18px;
    pointer-events: none; /* No interactúa con el mouse */
}

.conversations-list {
    /* Ajusta la altura según el diseño general */
    max-height: 100%;
}

.conversations-container {
    max-height: 300px; /* Define la altura máxima del contenedor */
    overflow-y: auto; /* Habilita el desplazamiento vertical */
}

.notification-indicator {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    right: 10px;  /* Ajusta según la posición que desees */
    top: 50%;
    transform: translateY(-50%);
}

.conversation-item {
    position: relative; /* Necesario para que el círculo rojo se posicione correctamente */
}

.chat-header-info {
    display: flex;
    align-items: center;
    gap: 10px; /* Espaciado entre los elementos */
}

.info-text-container {
    display: flex;
    align-items: center;
    gap: 5px; /* Espaciado entre el ícono y el texto */
}

.info-icon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d3d3d3; /* Color gris */
    color: #fff; /* Color del texto */
    font-size: 14px;
    font-weight: bold;
    border-radius: 50%;
}

.info-text {
    font-size: 14px;
    color: #d1d1d1; /* Gris claro para el texto */
    font-weight: 500;
}

@media (max-width: 768px) {
    /* Contenedor principal del chat */
    .chat-container2 {
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 10px;
        height: 100vh; /* Ocupa toda la altura de la pantalla */
        overflow: hidden; /* Evita que desborde */
    }

    /* Sección de Conversations */
    .conversations-list {
        display: flex;
        flex-direction: column;
        background-color: #f9f9f9;
        border-radius: 5px;
        padding: 10px;
        overflow-y: auto; /* Scroll vertical independiente */
        height: 40%; /* Ocupa el 40% de la pantalla */
        width: 100%; /* Usa el 100% del ancho disponible */
        box-sizing: border-box; /* Incluye el padding dentro del ancho */
    }

    .conversations-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .conversation-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: #ffffff;
        border-radius: 5px;
        border: 1px solid #ddd;
        font-size: 0.9rem;
        cursor: pointer;
        width: 100%; /* Asegura que cada elemento también ocupe todo el ancho */
    }

    .conversation-item:hover {
        background-color: #f2f2f2;
    }

    .conversation-item.selected {
        background-color: #e6e6e6;
        border-color: #ccc;
    }

    .notification-indicator {
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
    }

    /* Sección de chat */
    .chat-body-container {
        display: flex;
        flex-direction: column;
        flex: 1; /* Ocupa el espacio restante */
        overflow: hidden; /* Evita el desbordamiento */
        background-color: #f4f4f4;
        border-radius: 5px;
        padding: 10px;
        width: 100%; /* Asegura que el chat también ocupe todo el ancho */
    }

    .chat-body {
        flex: 1;
        overflow-y: auto; /* Scroll independiente para los mensajes */
        padding: 10px;
        background-color: #fff;
        border-radius: 5px;
    }

    .no-messages {
        text-align: center;
        font-size: 1rem;
        color: #666;
    }

    .chat-message {
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;
        font-size: 0.9rem;
    }

    .admin-message {
        background-color: #e6f7ff; /* Fondo claro para admin */
        text-align: left;
        align-self: flex-start;
    }

    .user-message {
        background-color: #ffe6e6; /* Fondo claro para usuario */
        text-align: left;
        align-self: flex-end;
    }

    .chat-message-content {
        margin-bottom: 5px;
    }

    .chat-message-timestamp {
        font-size: 0.8rem;
        color: #888;
        text-align: right;
    }

    /* Input del chat */
    .chat-input {
        display: flex;
        gap: 10px;
        padding: 10px;
        background-color: #f9f9f9;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%; /* Asegura que el input ocupe todo el ancho */
        box-sizing: border-box; /* Incluye padding en el cálculo del ancho */
    }

    .chat-input input {
        flex: 1; /* Ocupa todo el espacio disponible */
        border: none;
        outline: none;
        padding: 10px;
        font-size: 0.9rem;
        border-radius: 5px;
        background-color: #fff;
    }

    .chat-input button {
        padding: 10px 20px;
        font-size: 0.9rem;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .chat-input button:hover {
        background-color: #0056b3;
    }
}
