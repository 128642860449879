/* src/styles/register.css */

.register-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #51cda2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    text-align: center;
}

.register-title {
    color: #0d083f;
    font-size: 1.8em;
    margin-bottom: 20px;
}

.register-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.register-label {
    text-align: left;
    color: #0d083f;
    font-weight: bold;
    margin-bottom: 5px;
}

.register-input {
    background-color: #0d083f;
    color: white;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #0d083f;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
}

.register-input:focus {
    border-color: #3498db;
}

.register-button {
    background-color: #0d083f;
    color: #51cda2;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 10px;
}

.register-button:hover {
    background-color: #19107d;
    transform: scale(1.05);
}

.register-button:active {
    transform: scale(0.95);
}

.error-message {
    color: red;
    font-size: 0.9em;
}

.login-prompt {
    margin-top: 20px;
    font-size: 14px; /* Adjust the font size as needed */
}

.login-link {
    color: #19107d; /* Example color, change as necessary */
    text-decoration: none;
    font-weight: 600;
}

.login-link:hover {
    text-decoration: underline; /* Optional: add underline on hover */
}
