/* src/styles/login.css */

.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #51cda2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    text-align: center;
}

.login-title {
    color: #0d083f;
    font-size: 1.8em;
    margin-bottom: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.login-label {
    text-align: left;
    color: #0d083f;
    font-weight: bold;
    margin-bottom: 5px;
}

.login-input {
    background-color: #0d083f;
    color: white;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #0d083f;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
}

.login-input:focus {
    border-color: #3498db;
}

.login-button {
    background-color: #0d083f;
    color: #51cda2;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 20px;
}

.login-button:hover {
    background-color: #150e60;
    transform: scale(1.05);
}

.login-button:active {
    transform: scale(0.95);
}

/* src/styles/login.css */

.signup-link {
    margin-top: 25px;
    color: #0d083f;
}

.signup-link a {
    color: #19107d;
    text-decoration: none;
    font-weight: bold;
}

.signup-link a:hover {
    text-decoration: underline;
}

.login-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 48vh; /* Ocupa toda la altura disponible */
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.login-spinner p {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
}
