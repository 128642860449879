/* src/styles/navbar.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0d083f;
    padding: 7px 40px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Adjusted shadow for a stronger effect */
    z-index: 10;
}


.navbar-brand .logo {
    color: #51cda2;
    font-size: 2.4em;
    font-weight: bold;
    text-decoration: none;
}

.navbar-menu {
    display: flex;
    gap: 20px;
}

.navbar-item {
    color: #ffffff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 20px;
    font-weight: 600;
    z-index: 10;
}

.navbar-item:hover {
    transform: scale(1.05);
}

.navbar-item:active {
    transform: scale(0.95);
}

/* Estilo específico para el botón de iniciar sesión */
.login-button-2 {
    background-color: #e74c3c;
    color: #51cda2;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;
}

.login-button-2:hover {
    background-color: #c0392b;
    transform: scale(1.05);
}

.login-button-2:active {
    transform: scale(0.95);
}

.login-button-2,
.logout-button-2 {
    color: #0d083f;
    background-color: #51cda2;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.logout-button {
    margin-left: 15px;
}

.login-button-2:hover,
.logout-button-2:hover {
    background-color: #399072;
}

.user-menu {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #ffffff;
}

/* Estilo del dropdown */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #0d083f;
    color: #ffffff;
    font-size: 18px;
    border: none;
    cursor: pointer;
    font-weight: 600;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #51cda2;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content .navbar-item {
    color: #0d083f;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content .navbar-item:hover {
    background-color: #41a481;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.logo-image {
    height: 80px; /* Ajusta la altura según sea necesario */
    margin-right: 10px; /* Espacio entre el logo y el texto */
    vertical-align: middle; /* Alinea la imagen verticalmente con el texto */
}


.user-menu {
    position: relative; /* Positioning for the dropdown */
}

.user-dropdown-button {
    background: none; /* Remove default button styling */
    border: none;
    cursor: pointer;
    color: inherit; /* Use inherited text color */
    font-size: 22px; /* Adjust font size as needed */
    font-weight: 600;
}

.dropdown-menu {
    position: absolute;
    top: 100%; /* Position below the button */
    right: 0; /* Align to the right */
    background-color: #51cda2; /* Background color of the dropdown */
    border: 1px solid #51cda2; /* Border for the dropdown */
    box-shadow: 0 2px 8px #0d083f; /* Shadow for depth */
    z-index: 1000; /* Ensure it appears above other elements */
    width: 150px; /* Set a fixed width for the dropdown */
    display: flex;
    flex-direction: column; /* Stack items vertically */
}

.dropdown-item {
    padding: 10px 15px; /* Add padding for clickable area */
    text-decoration: none; /* Remove underline */
    color: #0d083f  ; /* Text color */
    transition: background 0.3s; /* Smooth background transition */
}

.dropdown-item:hover {
    background-color: #41a481; /* Change background on hover */
}

.user-dropdown-button {
    background: none; /* Remove default button styling */
    border: none;
    cursor: pointer;
    color: inherit; /* Use inherited text color */
    font-size: 22px; /* Adjust font size as needed */
    display: flex;
    align-items: center; /* Center align the text and arrow */
}

.dropdown-arrow {
    margin-left: 5px; /* Add space between the username and the arrow */
    font-size: 0.8rem; /* Adjust arrow size if needed */
    transition: transform 0.2s; /* Smooth rotation effect */
}

/* Add this class for when the dropdown is open */
.dropdown-open .dropdown-arrow {
    transform: rotate(180deg); /* Rotate the arrow when dropdown is open */
}


.user-menu .dropdown-item {
    background-color: transparent;
    border: none;
    color: #0d083f;
    font-size: 1rem;
    padding: 10px 15px;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.3s;
    font-weight: 600;
}

.user-menu .dropdown-item:hover {
    background-color: #41a481;
}

.carrito-button {
    padding: 0;
    background-color: transparent;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
  }
  
  .carrito-icon {
    width: 30px;
    height: 30px;
    fill: #ffffff; /* Color de relleno */
    stroke: #51cda2; /* Color del trazo */
  }
  
/* Estilo para el contenedor de "Messages" */
.dropdown-item {
    display: inline-flex; /* Para alinear los elementos horizontalmente */
    align-items: center; /* Alinea verticalmente */
    position: relative; /* Necesario para posicionar el círculo */
}

/* Estilo para el círculo rojo de notificación */
.notification-bubble {
    width: 10px; /* Tamaño del círculo */
    height: 10px; /* Tamaño del círculo */
    background-color: red; /* Color del círculo */
    border-radius: 50%; /* Forma circular */
    position: absolute; /* Posición absoluta dentro del contenedor */
    right: 5px; /* Alineación al lado derecho del texto */
}

/* Estilos para la vista móvil */
@media (max-width: 768px) {
    .navbar-menu {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #333;
    }

    .navbar-item {
        padding: 10px;
        text-align: center;
        width: 100%;
    }


    .navbar-menu.active {
        display: flex;
    }

    .menu-toggle {
        display: block;
        background: none;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }

    .logo {
        display: flex;
        align-items: center;
    }

    .user-dropdown-button {
        font-size: 16px;
        display: inline-block;
    }

    .user-menu .dropdown-menu {
        position: absolute;
        top: 50px; /* Espacio desde la parte superior del contenedor */
        left: 50%; /* Posición inicial centrada desde el lado izquierdo del contenedor */
        transform: translateX(-50%); /* Ajusta el centro horizontal */
        width: auto; /* Ajusta según sea necesario, o establece un tamaño fijo */
        padding: 10px; /* Espaciado interno opcional */
        border-radius: 5px; /* Esquina redondeada opcional */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Agrega un efecto de sombra */
    }
}

/* Estilos para la vista móvil */
@media (max-width: 768px) {
    /* Menú de navegación que inicialmente estará oculto */
    .navbar-menu {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 80px; /* Asegúrate de que esté por debajo del menú de hamburguesa */
        left: 0;
        background-color: #0d083f; /* Fondo del menú de hamburguesa */
        z-index: 10; /* Asegúrate de que el menú esté encima de otros elementos */
    }

    /* Los elementos del menú ocupan todo el ancho y tienen un color de texto blanco */
    .navbar-item {
        padding: 15px;
        text-align: center;
        width: 100%;
        color: white; /* Color de las letras */
        font-size: 18px; /* Tamaño de las letras uniforme */
        text-transform: uppercase; /* Poner las letras en mayúsculas */
        border-bottom: 1px solid #444;
    }

    /* Mostrar el menú cuando se activa la clase .active */
    .navbar-menu.active {
        display: flex;
    }

    /* Estilos para el ícono de hamburguesa */
    .navbar-burger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 25px;
        cursor: pointer;
        z-index: 100; /* El ícono debe estar encima del menú */
        background-color: transparent; /* Sin fondo */
        border: none; /* Sin borde */
    }

    .navbar-burger span {
        width: 100%;
        height: 4px; /* El grosor de las barras */
        background-color: #fff; /* Color blanco para las barras */
        transition: all 0.3s ease;
    }

    /* El botón de hamburguesa se muestra solo en pantallas pequeñas */
    .navbar-burger {
        display: flex;
    }
}

/* Estilo de la barra de navegación en pantallas más grandes */
@media (min-width: 769px) {
    .navbar-menu {
        display: flex;
        flex-direction: row;
        position: static;
        background-color: transparent;
        z-index: auto;
    }

    .navbar-item {
        padding: 10px;
        color: #fff;
        text-align: center;
        font-size: 18px; /* Tamaño uniforme para las letras */
    }

    .navbar-burger {
        display: none;
    }
}



.navbar-burger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.navbar-burger span {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
    transition: all 0.3s ease-in-out;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
}

.dropdown-content.open {
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    padding: 10px;
    border-radius: 5px;
}

.navbar-item .dropdown-content a {
    text-decoration: none;
    padding: 5px 0;
    display: block;
}

.dropdown-content a:hover {
    background-color: #444;
}

.navbar-item .dropdown-content.open {
    display: block;
}


/* Mobile adjustments */
@media screen and (max-width: 768px) {
    .navbar-menu {
        display: none;
        flex-direction: column;
        gap: 10px;
        position: absolute;
        top: 60px;
        left: 0;
        width: 85%;
        padding: 20px;
    }

    .navbar-menu.is-active {
        display: flex;
    }

    .carrito-button {
        margin-left: 0px;
      }

    .navbar-item {
        padding: 10px;
        text-align: center;
        font-size: 16px;
    }

    .navbar-burger {
        display: flex;
    }

    /* Dropdown for Boosting */
    .dropdown-content {
        display: none;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        border-radius: 5px;
        position: relative;
        z-index: 10;
    }

    .dropdown-content.open {
        display: flex;
    }

    .dropbtn {
        background: none;
        border: none;
        color: white;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
    }

/* Asegura que el contenedor esté alineado correctamente */
.user-menu {
    display: flex; /* Usar flexbox para alinear contenido */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente (opcional) */
    width: 100%; /* Asegúrate de que ocupe todo el ancho del contenedor */
    text-align: center; /* Aplica centrado al texto */
}

/* Asegúrate de que los elementos internos no tengan margen/padding que desplacen el texto */
.user-menu span, 
.user-menu button, 
.user-menu a {
    margin: 0;
    padding: 0;
    text-align: center;
}


    .dropdown-menu {
        display: none;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        border-radius: 5px;
        position: relative;
        z-index: 10;
    }

    .user-dropdown-button.dropdown-open + .dropdown-menu {
        display: flex;
    }

    .notification-dot {
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        right: 5px;
    }
}
