.payment-container {
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 600px;
    margin: 40px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.payment-loading,
.payment-error,
.payment-total {
    font-size: 18px;
    margin: 10px 0;
}

.payment-total {
    color: #6d1089;
    font-weight: bold;
}

.payment-error {
    color: #f44336;
}

.payment-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.payment-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    text-decoration: none;
}

.payment-button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.payment-paypal {
    background-color: #0070ba;
    color: #fff;
}

.payment-binance {
    background-color: #887852;
    color: #ffffff;
}

.payment-logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

