/* Contenedor principal de Overwatch */
.overwatch-contenedor {
    padding: 20px;
    text-align: center;
}

/* Título */
.overwatch-titulo {
    background-color: #191960;
    padding: 10px;
    margin-top: -16px;
    margin-right: -20px;
    margin-left: -50px;
}


.overwatch-titulo H1 {
    font-size: 36px;
    font-weight: bold;
    color: white;
    margin-left: -50px;
    background-color: #191960;
    padding: 20px;
    margin-top: -16px;
    margin-right: -20px;
}

.overwatch-titulo p {
    color: white;
    margin-top: -40px;
    margin-left: -50px;
    background-color: #191960;
}

/* Contenedor de los rangos alineados horizontalmente */
.overwatch-rangos-container {
    display: flex;
    justify-content: center;
    gap: 30px; /* Espacio entre los dos rangos */
}

/* Contenedor individual de cada rango */
.overwatch-seleccion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Contenedor para rango y logo */
.overwatch-rango {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Imagen del logo de rango */
.overwatch-rango-logo {
    width: 50px; /* Ajusta el tamaño del logo */
    height: 50px;
    margin-bottom: 10px; /* Espacio entre el logo y el selector */
}

/* Estilos del selector */
/* Estilos del selector */
.overwatch-select {
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: #333333; /* Fondo oscuro */
    color: white; /* Texto blanco */
    margin-top: 10px;
    cursor: pointer;
}


/* Estilos del botón */
.overwatch-boton {
    background-color: #3c3cc5;
    color: white;
    padding: 12px 30px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
}

.overwatch-boton:hover {
    background-color: #292997;
}

/* Estilo del precio total */
.overwatch-precio {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
}

/* Estilo general para la etiqueta (label) */
.overwatch-label {
    font-size: 1.1rem; /* Tamaño de fuente legible */
    font-weight: bold; /* Negrita para resaltar las etiquetas */
    color: #ffffff; /* Color oscuro para el texto */
    margin-bottom: 8px; /* Espacio entre la etiqueta y el campo de entrada */
    display: block; /* Asegura que la etiqueta se muestre en una línea nueva */
    text-align: left; /* Alinea el texto a la izquierda */
    padding-left: 10px; /* Espacio a la izquierda de la etiqueta */
}

/* Estilo para la etiqueta cuando está dentro de un contenedor con padding */
.overwatch-seleccion {
    padding: 10px;
    width: 100%;
}

/* Cambios de estilo para accesibilidad */
.overwatch-label:focus-within {
    color: #0066cc; /* Cambia el color a azul cuando la etiqueta está enfocada */
    transition: color 0.3s ease; /* Transición suave para el cambio de color */
}

/* Estilo para la etiqueta cuando el campo tiene un error */
.overwatch-label.error {
    color: red; /* Texto rojo cuando hay un error */
    font-weight: normal; /* Pesa normal la fuente */
}

/* Estilo de la etiqueta para un input deshabilitado */
.overwatch-label[disabled] {
    color: #b0b0b0; /* Color gris cuando el input está deshabilitado */
    font-style: italic; /* Texto en cursiva */
}

/* Contenedor de las selecciones alineadas horizontalmente */
/* Contenedor de las selecciones alineadas horizontalmente */
.overwatch-seleccion-row {
    display: flex; /* Alinea los elementos en una fila */
    justify-content: center; /* Centra las columnas en el contenedor */
    padding: 10px;
    width: 100%;
    gap: 10px; /* Reducido para que las columnas estén más juntas */
}

/* Contenedor individual de cada selección */
.overwatch-seleccion {
    display: flex;
    flex-direction: column; /* Mantiene los elementos de cada rango uno encima del otro */
    align-items: center; /* Centra el contenido de cada columna */
    justify-content: center;
    flex: 0.45; /* Asegura que cada columna ocupe un 45% del espacio disponible */
}

/* Estilo para la flecha entre los rangos */
.overwatch-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px; /* Espaciado a los lados */
}

.overwatch-arrow svg {
    width: 60px; /* Ajusta el tamaño de la flecha */
    height: 60px;
}

.overwatch-additional-options {
    margin-top: 20px;
}

.overwatch-additional-options h3 {
    margin-bottom: 10px;
    color: white; /* Letras blancas */
}

.overwatch-additional-options label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: white; /* Letras blancas */
}

/* Contenedor de las selecciones alineadas horizontalmente */
.overwatch-columns {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Alinea los elementos verticalmente al centro */
    gap: 20px; /* Ajusta el espacio entre columnas */
    padding: 10px; /* Espaciado interno para el contenedor */
}

/* Contenedor individual de cada selección */
.overwatch-seleccion {
    flex: 1; /* Cada columna ocupa un espacio igual */
}

/* Estilo para la flecha entre los rangos */
.overwatch-arrow {
    display: flex;
    align-items: center; /* Centra verticalmente la flecha */
    justify-content: center;
    margin: 0 10px; /* Ajusta el espacio lateral */
    flex: 0 0 50px; /* Tamaño fijo para la flecha */
}

/* Opciones adicionales más cerca de las otras columnas */
.overwatch-additional-options {
    flex: 1; /* Columna que ocupa un espacio igual al resto */
    font-size: 1.1rem;
}

.overwatch-additional-options h3 {
    margin-bottom: 10px;
    color: white; /* Letras blancas */
    text-align: left; /* Alinea el título a la izquierda */
}

.overwatch-additional-options label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    color: white; /* Letras blancas */
    text-align: left; /* Alinea las etiquetas a la izquierda */
}

/* Contenedor de las selecciones alineadas horizontalmente */
.overwatch-columns {
    display: flex;
    justify-content: center; /* Centra las columnas en el contenedor */
    align-items: center; /* Alinea los elementos verticalmente al centro */
    gap: 5px; /* Reduce el espacio entre columnas */
    padding: 5px; /* Espaciado interno reducido */
    margin-bottom: 10px;
    margin-left: 20px;
}

/* Contenedor individual de cada selección */
.overwatch-seleccion {
    flex: 1; /* Cada columna ocupa un espacio igual */
    padding: 50px 0px !important; /* Menor espacio interno */

}

/* Estilo para la flecha entre los rangos */
.overwatch-arrow {
    display: flex;
    align-items: center; /* Centra verticalmente la flecha */
    justify-content: center;
    margin: 0 0px; /* Espacio muy reducido entre la flecha y las columnas */
    flex: 0 0 40px; /* Tamaño fijo más compacto para la flecha */
}

/* Imagen del rango */
.rank-logo {
    margin-top: 10px; /* Espacio reducido entre el selector y la imagen */
    background-color: #3c3cc5;
    border-radius: 50%; /* Hace que el contenedor sea circular */
    padding: 30px 30px !important; /* Menor espacio interno */
}

/* Estilos del selector */
.overwatch-select {
    padding: 8px; /* Espacio interno reducido */
    font-size: 14px; /* Fuente más pequeña */
    margin-top: 5px; /* Espacio reducido arriba del selector */
}

/* Columna vacía */
.overwatch-empty-column {
    flex: 1; /* Ocupa un espacio proporcional para empujar las demás columnas */
    min-width: 100px; /* Ajusta el ancho mínimo de la columna vacía */
}

/* CSS for Role Selection */
.overwatch-roles {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
}

.overwatch-role {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;
}

.overwatch-role img {
    width: 50px; /* You can adjust this size as needed */
    height: 50px;
    margin-bottom: 5px;
    border-radius: 8px;
}

.overwatch-role span {
    font-weight: bold;
    color: #fff;
}

.overwatch-role.selected {
    border: 2px solid #51cda2; /* Green border when selected */
    padding: 5px;
    border-radius: 8px;
}

/* Additional styling for other parts */
.overwatch-additional-options {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Contenedor específico para la parte de botones y precio */
/* Contenedor específico para la parte de botones y precio */
.overwatch-right-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: auto; /* This pushes the section to the right */
    margin-right: 220px; /* Adds margin to the right */
    margin-top: -150px;
    
}

.overwatch-right-section-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: 220px;
    margin-top: -200px; /* Ajusta la separación hacia arriba */
    margin-bottom: 100px;
    z-index: 10; /* Asegura que esta sección esté por encima del footer */
}

.overwatch-right-section-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: 220px;
    margin-top: -40px; /* Ajusta la separación hacia arriba */
    margin-bottom: 0px;
    z-index: 10; /* Asegura que esta sección esté por encima del footer */
}


/* Estilos para el botón de "Agregar al Carrito" */
.overwatch-add-to-cart {
    background-color: #51cda2; /* Warm red/orange color for emphasis */
    color: white; /* White text */
    font-size: 16px; /* Appropriate font size */
    font-weight: bold; /* Make the text bold */
    padding: 12px 24px; /* Add padding for better click area */
    border: none; /* Remove default border */
    border-radius: 30px; /* Rounded corners for a modern look */
    cursor: pointer; /* Change cursor to indicate it’s clickable */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    text-align: center; /* Center text */
    width: 100%; /* Full width for the button */
    max-width: 230px; /* Limit max width */
    margin-top: 10px; /* Some space above the button */
}

/* Hover effect for the button */
.overwatch-add-to-cart:hover {
    background-color: #359372; /* Darker red when hovered */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

/* Disabled button state */
.overwatch-add-to-cart:disabled {
    background-color: #d3d3d3; /* Light grey for disabled state */
    cursor: not-allowed; /* Change cursor to indicate it's disabled */
    box-shadow: none; /* Remove shadow */
    opacity: 0.6; /* Reduce opacity */
}

.overwatch-add-to-cart[disabled]:hover {
    background-color: #c1c1c1; /* Keeps the color the same when hovered */
}

/* Style for the Add to Cart button */
.overwatch-add-to-cart {
    position: relative; /* To position the tooltip */
    transition: background-color 0.3s ease;
}


/* Tooltip Style */
.tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
}

/* Show tooltip when hovering over disabled button */
.overwatch-add-to-cart[disabled]:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

.overwatch-menu {

    padding: 20px; /* Padding around the content */
    border-radius: 15px; /* Rounded corners */
    margin-left: -30px; /* Align with the left side of the screen */
    display: flex;
    flex-direction: column; /* Arrange items vertically */
    width: 400px; /* Adjust the width as needed */
    gap: 20px;
}

.overwatch-option {
    background-color: #3c3cc5; /* Background color */
    color: white; /* White text */
    font-size: 24px; /* Larger text size */
    font-weight: normal;
    padding: 10px; /* Padding inside each option */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

.overwatch-option:hover {
    background-color: #191960; /* Light hover effect */
}

.overwatch-option:hover,
.overwatch-option:active {
    background-color: #191960(255, 255, 255, 0.3); /* Slightly stronger hover effect */
}

.column-overwatch {
    display: flex;
  }
  
  .column-left {
    flex: 1; /* 1 part */
    width: 20%; /* 1/5 of the page */
    margin-top: 20px;
  }
  
  .column-right {
    flex: 4; /* 4 parts */
    width: 80%; /* 4/5 of the page */
  }
  
  .center-ow{
    margin-left: -30%;
  }

  /* Style for the FAQ Section */
/* Wrapper for FAQ */
.overwatch-faq-wrapper {
    position: absolute;  /* Allow it to break out of the parent container */
    top: 30px;           /* Adjust this to control how far from the top of the parent container */
    left: 100%;          /* Place it to the right of the container */
    transform: translateX(20px);  /* Optional: Slight shift to the right if needed */
    width: calc(100% - 20px);  /* Adjust width if needed */
}
.overwatch-faq {
    margin-top: 60px;
    padding: 20px;
    border-radius: 8px;
    width: 100vh;
    margin-bottom: -20px;
}

.overwatch-faq-title {
    font-size: 24px;
    color: #f4f4f4;
    margin-bottom: 15px;
}

.overwatch-faq-item {
    margin-bottom: 15px;
}

.overwatch-faq-question {
    font-size: 18px;
    color: #3c3cc5; /* Or any color you prefer */
}

.overwatch-faq-answer {
    font-size: 16px;
    color: #f4f4f4;
    margin-top: 5px;
    padding-left: 10px;
}


/* Title */
.overwatch-faq-title {
    font-size: 24px;
    color: #f4f4f4;
    margin-bottom: 15px;
}

/* Individual FAQ Item */
.overwatch-faq-item {
    margin-bottom: 15px;
}

/* Question Styling */
.overwatch-faq-question {
    font-size: 18px;
    color: #fff; /* Text color */
    cursor: pointer;
    background-color: #3c3cc5; /* Background color */
    padding: 8px 15px; /* Add some padding for better spacing */
    border-radius: 12px; /* Rounded corners */
    transition: color 0.3s, text-decoration 0.3s, background-color 0.3s;
}

/* Underline effect on hover */
.overwatch-faq-question:hover {
    color: #f4f4f4; /* Change text color on hover */
    background-color: #2a2a8f; /* Darken the background color on hover */
    text-decoration: underline;
}

/* Answer Styling */
.overwatch-faq-answer {
    font-size: 16px;
    color: #f4f4f4;
    margin-top: 5px;
    padding-left: 10px;
    display: block;
}

.coming-soon {
    font-size: 3rem; /* Tamaño de fuente grande */
    color: white; /* Color blanco */
    text-align: center; /* Centrar el texto */
    font-weight: bold; /* Negrita */
    padding: 20px; /* Espaciado alrededor del texto */
    border-radius: 10px; /* Bordes redondeados opcionales */
    margin-top: 20px; /* Espacio superior */
    width: 100%; /* Asegura que el elemento ocupe todo el ancho disponible */
    display: block; /* Hace que el div ocupe toda la línea */
    position: relative; /* Asegura que se pueda centrar dentro de su contenedor */
    margin-right: 45vh;
}

.rank-logo-battlepass {
    width: 23%;
    height: auto;
    margin-left: -27%;
    margin-top: -180px;
    background-color: #3c3cc5;
    border-radius: 50%; /* Hace que el contenedor sea circular */
    padding: 10px 10px !important; /* Menor espacio interno */
}



/* Mobile-first styles */
@media only screen and (max-width: 768px) {
    html, body {
        overflow-x: hidden; /* Desactiva el desplazamiento horizontal globalmente */
        width: 100%; /* Asegura que el body no se expanda más allá del ancho de la pantalla */
        margin: 0; /* Elimina cualquier margen que podría causar un desbordamiento */
        padding: 0; /* Elimina cualquier padding que podría causar un desbordamiento */
    }

    .overwatch-container,
    .overwatch-columns,
    .overwatch-seleccion,
    .overwatch-additional-options {
        width: 100%; /* Asegura que los contenedores no se desborden */
        overflow-x: hidden; /* Evita el desplazamiento horizontal dentro de estos contenedores */
    }

    .overwatch-role img,
    .overwatch-role {
        max-width: 100%; /* Asegura que las imágenes no se desborden */
        width: 100%; /* Asegura que las imágenes no excedan el ancho */
        height: auto; /* Mantiene la proporción */
        box-sizing: border-box; /* Asegura que padding y border no causen desbordamiento */
    }

    /* Ajuste para evitar desbordamientos en títulos y párrafos */
    .overwatch-titulo h1,
    .overwatch-titulo p {
        margin: 0 10px; /* Ajusta márgenes laterales */
        padding: 0; /* Elimina cualquier padding que cause desbordamiento */
    }

    /* Asegurarse de que el botón y los selects no se desborden */
    .overwatch-boton,
    .overwatch-select {
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%; /* Asegura que ocupen todo el ancho disponible */
        box-sizing: border-box;
    }

    /* Asegura que la imagen del logo no se desborde */
    .rank-logo {
        width: 100%; /* Asegura que el logo se ajuste al ancho disponible */
        max-width: 50px; /* Limita el tamaño máximo */
    }
    
    /* Verifica que la clase .overwatch-arrow no cause desbordamiento */
    .overwatch-arrow {
        width: 100%;
    }
    
    .overwatch-contenedor {
        padding: 10px;
    }

    .overwatch-titulo h1 {
        font-size: 1.5rem;
        text-align: center;
    }

    .overwatch-titulo p {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 20px;
    }

    .column-overwatch {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .column-left,
    .column-right {
        width: 100%;
    }
    

    .overwatch-menu {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }


    .overwatch-seleccion {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .overwatch-select {
        width: 100%;
        max-width: 300px;
        font-size: 0.9rem;
    }

    .rank-logo {
        width: 50px;
        height: 50px;
    }

    .overwatch-arrow {
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    .overwatch-additional-options {
        width: 100%;
        margin-top: 20px;
        text-align: center; /* Asegura que el texto y las opciones se centren */
        display: flex;
        flex-direction: column;
        align-items: center; /* Centra las opciones dentro del contenedor */
    }

    .overwatch-roles {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .overwatch-role {
        width: 60px;
        height: 60px;
        text-align: center;
    }

    .overwatch-role img {
        width: 100%;
        height: auto;
    }

    .overwatch-right-section {
        text-align: center;
        margin-top: 20px;
        width: 100%;
    }

    .overwatch-precio {
        font-size: 1.2rem;
        margin-bottom: 10px; /* Espaciado para separar del botón */
        margin-right: 10vh;
    }

    .overwatch-add-to-cart {
        width: 100%;
        max-width: 300px;
        margin-top: 20px;
        text-align: center; /* Centra el botón en la sección */
        margin: 5vh;
    }

    .overwatch-faq {
        padding: 10px;
    }

    .overwatch-faq-title {
        font-size: 1.2rem;
        text-align: center;
    }

    .overwatch-faq-item {
        margin-bottom: 15px;
    }

    .overwatch-faq-question {
        font-size: 1rem;
        cursor: pointer;
    }

    .overwatch-faq-answer {
        font-size: 0.9rem;
        padding: 5px 0;
    }

    .coming-soon {
        text-align: center;
        font-size: 1.2rem;
        margin: 20px 0;
    }

    .overwatch-seleccion {
        display: flex;
        flex-direction: column;
    }

    .overwatch-additional-options {
        order: 2;
        margin-top: 20px;
        text-align: center;
        justify-content: center;
    }

    .overwatch-columns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
    }

    .center-ow {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        gap: 20px;
        width: 100%;
    }

    .overwatch-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 100vh;
        padding: 20px;
        box-sizing: border-box;
    }

    .overwatch-seleccion,
    .overwatch-additional-options {
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .overwatch-additional-options {
        order: 2;
        text-align: center;
        justify-content: center;
    }

    .overwatch-arrow {
        display: none;
    }

    .overwatch-container {
        padding: 10px;
    }

    .overwatch-columns {
        width: 100%;
        flex-direction: column;
    }

    .overwatch-right-section {
        width: 100%;
        text-align: center;
    }

    .overwatch-role {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    .overwatch-role img {
        max-width: 100px;
        margin-bottom: 10px;
    }

    .center-ow {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center horizontally */
        justify-content: center; /* Center vertically */
        width: 100%;
        padding: 20px;
        gap: 20px; /* Spacing between the elements */
        box-sizing: border-box; /* Ensures padding is respected */
    }

    .error-message {
        color: red;
        text-align: center;
        margin-top: 10px;
    }

    .overwatch-roles {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap; /* Permite que se ajusten en pantallas pequeñas */
        width: 100%;
        margin-top: 20px; /* Espacio entre los roles y el botón */
        margin-left: 25vh;
    }

    .overwatch-role {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        width: 60px;
        height: auto;
        text-align: center;
        margin-bottom: 20px; /* Espacio entre los roles */
    }

    .overwatch-role img {
        max-width: 60px;
        height: auto;
        margin-bottom: 10px; /* Espacio entre la imagen y el texto */
    }

    .overwatch-boton {
        width: 100%;
        max-width: 300px;
        font-size: 1.1rem;
        padding: 10px;
        margin-top: 20px; /* Espacio entre el botón y los roles */
        text-align: center; /* Centra el texto dentro del botón */
        margin-left: 27vh;
    }
    
    
    .overwatch-select {
        width: 100%; /* Asegura que el select ocupe todo el ancho */
        font-size: 1rem;
        padding: 10px;
        text-align: center; /* Centra el texto */
        margin-bottom: 10px; /* Espacio entre los selects */
    }

    .overwatch-titulo h1 {
        font-size: 1.8rem; /* Ajusta el tamaño del título en móvil */
        text-align: center; /* Centra el texto */
        margin: 0 10px; /* Agrega un margen pequeño a los lados */
        margin-left: 30px;
    }

    .overwatch-titulo p {
        font-size: 1rem; /* Ajusta el tamaño del párrafo en móvil */
        text-align: center; /* Centra el texto */
        margin: 0px 10px; /* Agrega un margen pequeño a los lados */
        margin-left: 40px;
    }

    .background-faq {
        display: none; /* Se oculta todo el contenedor */
    }
}

.quickplay-section {
    background-color: #030315;
    padding: 20px;
    color: #fff;
    font-family: Arial, sans-serif;
}

.quickplay-content {
    display: flex;
    justify-content: flex-start; /* Alinea los elementos al inicio (izquierda) */
    align-items: flex-start; /* Alinea los elementos al inicio en el eje vertical también */
    margin-bottom: 20px;
    margin-top: 50px;
}

.quickplay-left {
    flex: 1;
    margin-right: 20px;
}

.quickplay-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -165px;
}

.quickplay-form h3 {
    margin-bottom: 10px;
}

.quickplay-quantity {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.quickplay-quantity button {
    background-color: #51cda2;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin: 0 5px;
    font-size: 18px;
}

.quickplay-quantity-input {
    width: 60px;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #51cda2;
    background-color: #1d1d2b;
    color: white;
}

.quickplay-game-select {
    width: 30%;
    padding: 10px;
    font-size: 16px;
    background-color: #1d1d2b;
    color: #fff;
    border: 1px solid #51cda2;
    margin-top: 10px;
    margin-left: 100px;
    max-width: 300px;
}



.quickplay-logo-img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    background-color: #3c3cc5;
    border-radius: 50%; /* Hace que el contenedor sea circular */
    padding: 30px 30px !important; /* Menor espacio interno */
}

.quickplay-price-calculator p,
.quickplay-price-calculator h4 {
    font-size: 18px;
    color: #51cda2;
}

.quickplay-options {
    display: flex;
    justify-content: space-between;
}

.quickplay-summary {
    flex: 1;
    text-align: center;
}

.quickplay-center {
    display: flex;
    flex-direction: column;  /* Apila los elementos en columna */
    align-items: center;  /* Centra los elementos horizontalmente */
    justify-content: center;  /* Centra los elementos verticalmente */
    text-align: center;  /* Centra el texto dentro de los elementos */
    padding: 20px;  /* Opcional, para agregar un poco de espacio alrededor */
}


.quickplay-form {
    width: 100%;  /* Puede ajustar el ancho a lo que necesites */
    max-width: 600px;  /* Establece un máximo ancho para evitar que crezca demasiado */
    margin-top: 20px;
}

.quickplay-form h3{
    color: white;
    margin-bottom: 30px;
}

.quickplay-quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.quickplay-quantity input {
    width: 60px; /* Ajusta el tamaño del input */
    text-align: center;
    margin: 0 10px;  /* Espacio entre los botones y el input */
}

.quickplay-buttons {
    margin-top: 20px;
}

/* Para ocultar las flechitas en los navegadores basados en WebKit (Chrome, Safari, Edge) */
.quickplay-quantity-input::-webkit-outer-spin-button,
.quickplay-quantity-input::-webkit-inner-spin-button {
    -webkit-appearance: none;  /* Elimina las flechitas */
    margin: 0; /* Elimina el margen adicional */
}

/* Propiedad estándar para asegurar compatibilidad */
.quickplay-quantity-input {
    -webkit-appearance: none; /* Chrome, Safari */
    -moz-appearance: none;    /* Firefox */
    appearance: none;         /* Propiedad estándar */
}

.quickplay-label {
    color: white;
    margin-left: 90px;
}

.quickplay-game-select {
    background-color: #3c3cc5; /* Color de fondo */
    color: white; /* Texto blanco */
    border: 2px solid #51cda2; /* Borde con color #51cda2 */
    border-radius: 5px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    font-size: 16px; /* Tamaño de fuente */
    font-family: Arial, sans-serif; /* Fuente */
    cursor: pointer; /* Cambia el cursor cuando pasa sobre el select */
    transition: all 0.3s ease; /* Suaviza el cambio de estilo */
}

/* Efecto cuando el select está en foco */
.quickplay-game-select:focus {
    outline: none; /* Elimina el borde por defecto del foco */
    border-color: #51cda2; /* Mantén el borde con el color deseado */
    background-color: #4c4ccf; /* Color ligeramente más oscuro al estar en foco */
}

/* Estilos para las opciones dentro del dropdown */
.quickplay-game-select option {
    background-color: #3c3cc5; /* Fondo para las opciones */
    color: white; /* Texto blanco */
}

/* Cambio de color para las opciones al pasar el ratón */
.quickplay-game-select option:hover {
    background-color: #51cda2; /* Color de fondo cuando el mouse pasa sobre las opciones */
    color: white; /* Mantener el texto blanco */
}

.opciones{
    margin-left: -70px;
}

@media (max-width: 767px) {

    /* Asegura que todos los elementos estén en una fila distinta */
    .quickplay-content {
        display: block;
    }

    /* Asegurar que cada sección esté en una fila distinta */
    .quickplay-left, .quickplay-right, .quickplay-form, .overwatch-additional-options, .overwatch-right-section-2 {
        width: 100%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .quickplay-left{
        margin-left: -50px;
        margin-top: -20px;
    }

    /* Ajustes para la cantidad de quickplay */
    .quickplay-quantity {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
    }

    /* Botones de cantidad */
    .quickplay-quantity button {
        width: 30%;
        font-size: 16px;
    }

    .quickplay-quantity-input {
        width: 40%;
        text-align: center;
        font-size: 16px;
    }

    /* Elimina márgenes en el formulario */
    .quickplay-form {
        margin: 0;
        padding: 0;
    }

    /* Ajuste para el total de precio */
    .overwatch-precio {
        text-align: center;
    }

    /* Ocultar FAQ en dispositivos móviles */
    .overwatch-faq {
        display: none;
    }

    /* Ajustar el tamaño de los botones */
    .overwatch-add-to-cart {
        width: 100%;
    }

    .quickplay-buttons button{
        margin: 0px;
    }
    

    /* Ajustar la visibilidad del logo */
    .quickplay-logo-img {
        max-width: 80%;
        margin: 0 auto;
    }

    /* Ajuste de los campos de opciones adicionales */
    .overwatch-additional-options label {
        display: block;
        margin: 10px 0;
    }
}

.battlepass-level-inputs {
    display: flex;
    justify-content: center; /* Centra los elementos horizontalmente */
    align-items: center; /* Centra los elementos verticalmente */
    width: 70%;
    background-color: #030315;
    padding: 20px;
    border-radius: 10px;
    gap: 200px;
    margin-top: -20vh;
}

.battlepass-label {
    color: white;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    text-align: left; /* Alinea el texto de la etiqueta a la izquierda */
    width: auto; /* Permite que los labels se ajusten al contenido */
    gap: 5px; /* Espacio entre el texto del label y el input */
}

.input-container {
    display: flex;
    justify-content: center; /* Centra los elementos dentro del contenedor */
    align-items: center;
    gap: 5px; /* Reducción de espacio entre el input y los botones */
}

.battlepass-input {
    width: 100px; /* Aumenta el ancho del input */
    padding: 15px; /* Aumenta el padding para hacerlo más grande */
    text-align: center;
    font-size: 20px; /* Aumenta el tamaño de la fuente */
    color: #030315;
    background-color: white;
    border: 2px solid #3c3cc5;
    border-radius: 10px; /* Bordes más redondeados */
    margin: 0; /* Elimina márgenes */
    appearance: none; /* Elimina las flechas del input */

}

.input-button {
    width: 50px; /* Aumenta el tamaño de los botones */
    height: 50px;
    background-color: #51cda2;
    border: none;
    border-radius: 50%; /* Hace los botones redondos */
    color: white;
    font-size: 24px; /* Aumenta el tamaño de la fuente dentro del botón */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0; /* Elimina márgenes */
}

.input-button:hover {
    background-color: #3c3cc5; /* Cambia el color cuando el cursor está sobre el botón */
}

.input-button:active {
    background-color: #51cda2; /* Vuelve al color original cuando el botón se presiona */
}

.input-button:hover {
    background-color: #3c3cc5;
}

.input-button:active {
    background-color: #51cda2;
}

.battlepass-input:focus {
    outline: none;
    border-color: #51cda2;
}

.battlepass-label span {
    font-size: 14px;
    color: #f28f8f;
    margin-top: 5px;
}

.overwatch-boton2 {
    background-color: #3c3cc5;
    color: white;
    padding: 12px 30px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    margin-left: -27%;
    position: relative; /* Permite usar top */
}



.battlepass-center {
    display: flex; /* Utiliza flexbox para alinear los elementos en una fila */
    justify-content: flex-start; /* Alinea los elementos al principio (puedes cambiarlo a center si prefieres centrar todo) */
    align-items: center; /* Centra verticalmente los elementos dentro del contenedor */
    gap: 20px; /* Añade espacio entre los elementos */
    width: 100%; /* Asegura que ocupe todo el ancho disponible */
    flex-wrap: nowrap; /* Evita que los elementos se desborden a la siguiente línea */
}


.battlepass-slider {
    position: absolute;
    width: 100%;
    appearance: none;
    background: transparent;
    pointer-events: auto; /* Habilitar interacción según lógica */
}


.battlepass-slider {
    width: 12%; /* Ajusta el ancho para que no sobresalga del contenedor */
    appearance: none;
    height: 3px; /* Reduce la altura del slider */
    background-color: #ddd;
    border-radius: 5px;
    margin-top: 12vh; /* Incrementa la separación del input */
    cursor: pointer;
    display: block; /* Asegura que se quede dentro del contenedor */
}

.battlepass-slider::-webkit-slider-thumb {
    appearance: none;
    width: 15px; /* Reduce el tamaño del "thumb" */
    height: 15px;
    background-color: #51cda2;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #3c3cc5;
}

.battlepass-slider::-moz-range-thumb {
    width: 15px; /* Reduce el tamaño del "thumb" */
    height: 15px;
    background-color: #51cda2;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #3c3cc5;
}

.overwatch-faq2 {
    padding: 20px;
    border-radius: 8px;
    width: 100vh;
    margin-bottom: -20px;
}

@media only screen and (max-width: 768px) {

    .battlepass-content {
        width: 100%;
    }

    .battlepass-form {
        width: 100%;
    }



    .battlepass-label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .input-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
    }

    .battlepass-slider {
        display: none;
    }

    .overwatch-right-section-3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 10px;
    }

}


@media only screen and (max-width: 768px) {
    .column-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;  /* Espaciado entre los elementos */
        width: 100%;
    }

    .battlepass-content {
        width: 100%;
        padding: 0;  /* Elimina el margen */
    }

    .battlepass-form {
        width: 100%;
        margin: 0;  /* Elimina el margen */
        padding: 0;
    }

    .battlepass-level-inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
    }

    .battlepass-label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .input-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
    }

    .battlepass-slider {
        width: 100%;
    }


    .quickplay-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        width: 100%;
    }

    .overwatch-right-section-3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 15px;
    }

    .overwatch-faq2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        width: 100%;
        padding: 0;
    }

    .overwatch-faq-item {
        width: 100%;
    }

    .battlepass-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 15px; /* Espaciado entre los elementos */
        margin-top: 20vh;
    }

    .overwatch-boton2{
        margin-top: -11vh !important;
    }
    .rank-logo-battlepass{
        display: none;
    }

    .opciones{
        margin-left: 0px;
    }

    .quickplay-buttons{
        margin-top: 40px;
    }
}

.placement-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
}

.placement-column-left,
.placement-column-center,
.placement-column-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
}

.placement-rank-logo {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}

.placement-dropdown {
    font-size: 18px;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
}

.placement-image {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
}

.placement-range {
    width: 100%;
    margin-bottom: 10px;
}

.placement-selected-games {
    font-size: 16px;
}

.placement-roles {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}

.placement-role {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid transparent;
}

.placement-role.selected {
    border-color: blue;
}

.placement-calculate {
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 20px;
}

.placement-additional-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.placement-total-section {
    margin-top: 20px;
    text-align: center;
}

.placement-add-to-cart {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.placement-add-to-cart:disabled {
    background-color: grey;
    cursor: not-allowed;
}

.overwatch-seleccion {
    margin-bottom: 20px;
    text-align: center;
}

.overwatch-slider-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.overwatch-range {
    width: 90%; /* Slider más largo */
    height: 8px;
    background: #ccc;
    border-radius: 5px;
    outline: none;
    transition: background 0.3s;
}

.overwatch-range:hover {
    background: #888;
}

.overwatch-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s;
}

.overwatch-range::-webkit-slider-thumb:hover {
    background: #51cda2;
}

.overwatch-range::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #51cda2;
    border-radius: 50%;
    cursor: pointer;
}

.overwatch-seleccion {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px; /* Espaciado uniforme entre los elementos */
}

.overwatch-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px; /* Asegura un espacio debajo de la etiqueta */
}

.placement-selected-games-box {
    font-size: 18px;
    font-weight: bold;
    color: #51cda2;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    min-width: 50px;
    text-align: center;
}


.overwatch-slider-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.placement-image {
    width: 350px; /* Ajusta el tamaño según sea necesario */
    height: auto;
    margin-bottom: 15px; /* Espacio entre la imagen y el texto "Number of Games" */
    display: block;
}

.win-rate-text {
    font-size: 20px;
    font-weight: bold;
    color: #51cda2; /* Verde llamativo */
    text-align: center;
    margin-top: 50px; /* Espaciado debajo del texto */
}

.overwatch-select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px; /* Bordes redondeados */
    background-color: #f9f9f9;
    color: #333;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.overwatch-select:hover {
    border-color: #51cda2; /* Color verde en hover */
    background-color: #e8f5e9; /* Fondo claro al pasar el mouse */
}

.overwatch-select:focus {
    border-color: #51cda2;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Efecto de enfoque */
}

.overwatch-select option {
    background-color: #fff;
    color: #333;
    padding: 10px;
}

.back-faq{
    margin-top: 40px;
}

.wins-image {
    width: 70%;
    height: auto;
    margin-bottom: -80px;
    filter: hue-rotate(120deg) saturate(120%); /* Ajusta el tono más hacia el verde */
    transition: filter 0.3s ease; /* Transición suave */
}


.column-right2 {
    flex: 4; /* 4 parts */
    width: 80%; /* 4/5 of the page */
    margin-top: -20px;
  }