.titulo-orden {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: white;
}

.tabs-orden {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.tab-orden {
    background-color: #444;
    color: white;
    border: none;
    padding: 12px 25px;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-orden.active-orden {
    background-color: white;
    color: #030315;
    font-weight: bold;
}

.tab-orden:hover {
    background-color: #75FACC;
    color: #030315;
}

.tabla-orden {
    width: 100%;
    border-collapse: collapse;
    margin: 30px auto;
    background-color: #1c1c2b;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.tabla-orden th,
.tabla-orden td {
    padding: 15px 20px;
    text-align: left;
    color: #fff;
}

.tabla-orden th {
    background-color: #444;
    text-transform: uppercase;
    font-size: 0.9rem;
    border-bottom: 2px solid #75FACC;
}

.tabla-orden td {
    border-bottom: 1px solid #333;
}

.tabla-orden tr:nth-child(even) {
    background-color: #2a2a3f;
}

.tabla-orden tr:hover {
    background-color: #444;
}

.detalles-btn-orden {
    background-color: #444;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.detalles-btn-orden:hover {
    background-color: #75FACC;
    color: #030315;
    transform: scale(1.05);
}

.modal-overlay-orden {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content-orden {
    background-color: #1c1c2b;
    color: white;
    padding: 30px;
    border-radius: 10px;
    max-width: 90%;
    width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.modal-title-orden {

    color: white;
    font-size: 1.5rem;
    padding: 15px 20px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
}

.detalle-orden-table-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px 0;
}

.detalle-orden-table-section {
    flex: 1;
    min-width: 45%;
    max-width: 48%;
}

.detalle-orden-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1c1c2b;
    border-radius: 5px;
    overflow: hidden;
}

.detalle-orden-title {
    font-weight: bold;
    color: white;
    padding: 10px;
    text-align: left;
    background-color: #333;
    border-bottom: 1px solid #444;
}

.detalle-orden-value {
    color: #fff;
    padding: 10px;
    text-align: left;
}

.detalle-orden-table tr:nth-child(even) {
    background-color: #2a2a3f;
}

.detalle-orden-table tr:hover {
    background-color: #444;
}

.detalle-orden-table td {
    border: 1px solid #444;
}

.close-modal-btn-orden {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.close-modal-btn-orden:hover {
    background-color: #75FACC;
    color: #030315;
}

.contenedor-ordenes {
    max-width: 90%; /* Limitar el ancho al 90% del viewport */
    padding: 20px;
    background-color: #030315; /* Fondo oscuro consistente */
    border-radius: 10px; /* Bordes redondeados */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Sombra ligera para resaltar */
    margin: 20px 5%; /* Centra el contenedor horizontalmente y añade espacio vertical */
    display: flex;
    flex-direction: column;
    align-items: center; /* Asegura que los elementos dentro del contenedor también estén centrados */
}


.tabla-orden {
    width: 100%;
    border-collapse: collapse;
    margin: 40px 0; /* Margen vertical adicional */
    background-color: #1c1c2b;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.boost-specifications-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrar horizontalmente */
    justify-content: center; /* Centrar verticalmente si necesario */
    text-align: center; /* Centrar texto */
    padding: 20px; /* Espaciado interno */
  }
  

  /* Ocultar el encabezado en pantallas móviles */
@media (max-width: 768px) {
    .tabla-orden th {
        display: none; /* Oculta encabezados en mobile */
    }

    .tabla-orden tr {
        display: block; /* Cada fila ocupa toda la pantalla */
        margin-bottom: 10px; /* Espaciado entre filas */
        border: 1px solid #444;
        border-radius: 5px; /* Bordes redondeados */
        background-color: #333; /* Fondo para filas */
    }

    .tabla-orden td {
        display: flex;
        justify-content: space-between; /* Ajusta texto de lado a lado */
        padding: 5px; /* Menos espaciado en mobile */
        border: none; /* Sin bordes entre celdas */
    }

    .tabla-orden td::before {
        content: attr(data-label); /* Usar los encabezados como etiquetas */
        flex-basis: 40%; /* Tamaño del título */
        font-weight: bold;
        color: #75FACC;
        text-transform: uppercase;
        font-size: 0.8rem;
    }
}

.info-mensajes {
    margin: 20px auto; /* Centrado y espaciado */
    padding: 15px;
    max-width: 90%; /* Limitar el ancho */
    background-color: #1c1c2b; /* Fondo oscuro */
    border-radius: 10px; /* Bordes redondeados */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Sombra ligera */
    text-align: center; /* Centrar texto */
    margin-top: 0px;
  }
  
  .info-cuentas, .info-boost {
    font-size: 1rem; /* Tamaño de fuente */
    color: #75FACC; /* Color azul claro */
    margin: 10px 0; /* Espaciado entre los mensajes */
    line-height: 1.5; /* Mejora la legibilidad */
  }
  
  .info-cuentas {
    font-weight: bold; /* Resalta el texto */
  }
  
  .info-boost {
    font-weight: bold; /* Texto ligeramente menos destacado */
  }
  