@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
    background-color: #030315 !important;
    margin: 0; /* Asegúrate de que no haya margen en el body */
    font-family: 'Poppins', sans-serif;
}

.container-home {
    display: flex; /* Cambia a flexbox para el contenedor principal */
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Alinea el contenido al inicio verticalmente */
    flex-direction: column; /* Asegura que los elementos se apilen verticalmente */
    overflow: hidden; /* Oculta el contenido que se sale del contenedor */
}

/* Estilos generales para pantallas grandes (PC) */
.main-content {
    display: flex;
    flex-direction: row; /* Los elementos estarán en fila en pantallas grandes */
    justify-content: space-between; /* Espacio entre el texto y la imagen */
    align-items: flex-start; /* Alineación vertical al inicio */
    max-width: 1400px; /* Ancho máximo */
    width: 100%; /* Ancho completo */
    gap: 20px; /* Espacio entre los elementos */
    margin: 0 auto; /* Centra el contenido horizontalmente */
}

.text-content {
    flex: 1; /* Ocupa el 50% del ancho */
    max-width: 50%; /* Limita el ancho al 50% */
    margin-top: 30px; /* Espacio superior */
}

.video-content {
    flex: 1; /* Ocupa el 50% del ancho */
    max-width: 100%; /* Limita el ancho al 50% */
    position: relative; /* Necesario para el posicionamiento absoluto del hijo */
    height: 550px; /* Define una altura para el contenedor */
}

/* Estilos para pantallas móviles (menos de 768px) */
@media (max-width: 768px) {
    .main-content {
        flex-direction: column; /* Cambia la dirección de los elementos a columna */
        justify-content: center; /* Centra los elementos verticalmente */
        align-items: center; /* Centra los elementos horizontalmente */
        gap: 20px; /* Espacio entre los elementos */
        margin-bottom: 20px;
    }

    .text-content {
        max-width: 90%; /* Ancho máximo de 90% en pantallas pequeñas */
        text-align: center; /* Centra el texto */
        margin-top: 10px; /* Ajusta el margen superior */
    }

    .video-content {
        display: none; /* Oculta la imagen en pantallas pequeñas */
    }
}

.text-content h1 {
    font-size: 60px;
    margin-bottom: 30px;
    color: #75FACC;
}

.text-content h1 span{
    margin-bottom: 15px;
    color: #03031F;
    background-color: #75FACC;
    border-radius: 20px;
    padding: 0 16px;
    padding-bottom: 3px;
    
}

.text-content h2 {
    font-size: 22px;
    color: #ffffff;
    text-align: justify;
}

.video-content img {
    position: absolute;
    right: 100;
    top: 0;
    animation: slideIn 1s forwards;
    width: auto;
    height: 100%; /* O 'height: auto;' para mantener proporciones */
    max-height: none;
    border: none;
    clip-path: polygon(38% 0, 100% 0, 100% 100%, 40% 100%, 24% 50%);
}








.highlight-section-home {
    padding: 40px 20px; /* Espaciado alrededor de la sección */
    color: white;
    margin-top: 30px; /* Espacio entre main-content y highlight-section */
    text-align: center; /* Centra el texto dentro de la sección */
    display: flex; /* Cambia a flex para centrar el contenido */
    flex-direction: column; /* Asegura que el contenido se apile verticalmente */
    align-items: center; /* Centra los elementos horizontalmente */
}

.highlight-title-home {
    font-size: 30px;
    color: white;
    background-color: rgb(57, 0, 62);
    border-radius: 60px;
    padding: 10px 20px;
    margin-bottom: 60px;
    text-align: center; /* Centra el texto dentro del título */
}

.highlight-cards-home {
    display: flex; /* Usamos flexbox para las tarjetas */
    justify-content: space-between; /* Distribuye las tarjetas uniformemente */
    flex-wrap: wrap; /* Permite que las tarjetas se ajusten a una nueva línea si es necesario */
    gap: 150px; /* Espacio entre tarjetas */
    width: 100%; /* Asegúrate de que el contenedor ocupe todo el ancho */
    max-width: 1200px; /* Establece un ancho máximo para la sección */
    margin: 0 auto; /* Centra el contenedor */
    padding: 0 20px; /* Añade padding lateral para evitar que las tarjetas toquen los bordes */
}

.card-container-home {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px; /* Ancho de las tarjetas */
    flex: 1 1 300px; /* Permite que las tarjetas crezcan con un mínimo de 300px */
    transition: opacity 0.5s ease; /* Suaviza la transición */
}

.card-home {
    background-color: #0d083f;
    color: rgb(57, 0, 62);
    border-radius: 10px;
    padding: 20px;
    width: 100%; /* Asegura que la tarjeta ocupe el 100% de su contenedor */
    text-align: center;
    position: relative;
    z-index: 2;
    border: 4px solid #51cda2; /* Borde grueso alrededor de la tarjeta */
    transition: opacity 1s ease; /* Cambia la opacidad en 1 segundo con una transición suave */
}

/* Clase que activa la animación cuando se muestra en pantalla */
.card-container-home.animated {
    opacity: 1; /* Cambia la opacidad a 1 cuando la clase "animated" está presente */
}

/* Añadir un retraso a cada tarjeta si tienes múltiples tarjetas */
.card-container-home:nth-child(1).animated {
    animation-delay: 0.3s; /* Retraso para la primera tarjeta */
}

.card-container-home:nth-child(2).animated {
    animation-delay: 0.6s; /* Retraso para la segunda tarjeta */
}

.card-container-home:nth-child(3).animated {
    animation-delay: 0.9s; /* Retraso para la tercera tarjeta */
}


/* Elimina el pseudo-elemento ::before para quitar la sombra */


.card-title-home {
    font-size: 28px;
    color: #51cda2;
    margin-bottom: 30px;
    background-color: #0d083f;
    border-radius: 20px;
    padding: 5px 0;
}

.card-logo-home {
    height: 190px;
    width: auto;
}

.card-text-home {
    font-size: 22px;
    color: #ffffff;
    font-weight: 500;
}

.highlight-title-home {
    font-size: 30px;
    color: #51cda2;
    background-color: #0d083f;
    border-radius: 60px;
    padding: 10px 20px;
    margin-bottom: 60px;
    text-align: center; /* Centra el texto dentro de la sección */
}



/* Styles for the logo section */
.logo-section-home {
    width: 100%; /* Full width */
    height: 150px; /* Fixed height for the section */
    padding: 10px 0; /* Add padding vertically */
    background-color: #07073b; /* Dark background color */
    text-align: center; /* Center-align text */
    position: relative; /* Necessary for positioning buttons */
    overflow: hidden; /* Hide any overflow from larger logos */
}

.logo-container {
    display: flex; /* Use flexbox for layout */
    justify-content: center; /* Center logos horizontally */
    align-items: center; /* Center logos vertically */
    height: 100%; /* Make container fill the section height */
    gap: 50px; /* Space between logos */
}

.logo-image-1,
.logo-image-2,
.logo-image-3 {
    width: 200px; /* Fixed width for all logos */
    height: auto; /* Maintain aspect ratio */
    max-height: 100%; /* Limit max height to section height */
    object-fit: contain; /* Maintain aspect ratio */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
    cursor: pointer;
}

.logo-image-1:hover,
.logo-image-2:hover,
.logo-image-3:hover {
    transform: translateY(-5px); /* Slide up effect on hover */
}

@media (max-width: 768px) {
    /* Ocultar el carrusel en pantallas móviles */
    .logo-section-home {
        display: none; /* Oculta todo el carrusel */
    }
}

/* Styles for navigation buttons */
.nav-button {
    background-color: #07073b; /* Color del botón */
    border: none;
    color: white;
    padding: 200px;
    margin: 0; /* Sin margen para evitar desbordamientos */
    font-size: 28px;
    position: absolute; /* Posicionamiento absoluto */
    top: 50%; /* Coloca el botón en el medio de la altura */
    transform: translateY(-50%); /* Ajusta el botón hacia arriba para centrarlo */
    transition: transform 0.3s ease; /* Transición suave para el efecto */
}

.nav-button.left {
    left: 20px; /* Position the left button */
    cursor: pointer;
}

.nav-button.right {
    cursor: pointer;
    right: 20px; /* Position the right button */
}

.nav-button:hover {
    transform: translateY(-50%) scale(1.1); /* Agrandar en hover */
}

/* Styles for the logo container */
.logo-container {
    display: flex; /* Use flexbox for layout */
    justify-content: center; /* Center logos horizontally */
    align-items: center; /* Center logos vertically */
    height: 100%; /* Make container fill the section height */
    gap: 50px; /* Space between logos */
    position: relative; /* Allow positioning of child elements */
    transition: transform 0.5s ease-in-out; /* Smooth transition for sliding */
}

/* Animación para el LOGO que aparece desde la derecha */
.logo-enter {
    animation: slideInRight 0.5s forwards; /* Aplica la animación de deslizamiento */
}

/* Definición de la animación para que el logo se desplace desde la derecha */
@keyframes slideInRight {
    0% {
        transform: translateX(100%); /* Comienza fuera de la vista a la derecha */
        opacity: 0; /* Comienza invisible */
    }
    100% {
        transform: translateX(0); /* Termina en su posición final */
        opacity: 1; /* Termina visible */
    }
}

/* Clase para el LOGO que sale hacia la izquierda */
.logo-slide-out-left {
    animation: slideOutLeft 0.5s forwards; /* Aplica la animación de salida hacia la izquierda */
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0); /* Posición inicial */
        opacity: 1; /* Visible */
    }
    100% {
        transform: translateX(-100%); /* Desplazarse fuera de la vista a la izquierda */
        opacity: 0; /* Desvanecerse */
    }
}


@keyframes slideOutLeft {
    0% {
        transform: translateX(0); /* Posición inicial */
        opacity: 1; /* Visible */
    }
    100% {
        transform: translateX(-100%); /* Desplazarse fuera de la vista a la izquierda */
        opacity: 0; /* Desvanecerse */
    }
}

/* Clase para mover los logos a la izquierda */
.logo-slide-to-left {
    animation: slideToLeft 0.5s forwards; /* Aplica la animación de desplazamiento */
}

@keyframes slideToLeft {
    0% {
        transform: translateX(0); /* Posición inicial */
    }
    100% {
        transform: translateX(-100%); /* Moverse a la izquierda */
    }
}

/* Animación para el LOGO que aparece desde la derecha */
.logo-enter {
    animation: slideInRight 0.5s forwards; /* Aplica la animación de deslizamiento */
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%); /* Comienza fuera de la vista a la derecha */
        opacity: 0; /* Comienza invisible */
    }
    100% {
        transform: translateX(0); /* Termina en su posición final */
        opacity: 1; /* Termina visible */
    }
}
.nav-button {
    background-color: transparent; /* Fondo transparente */
    border: none; /* Sin borde */
    cursor: pointer; /* Cambia el cursor a mano */
    font-size: 24px; /* Tamaño de la fuente */
    position: absolute; /* Posición absoluta para alinearlos */
    top: 50%; /* Centrado verticalmente */
    transform: translateY(-50%); /* Alineación vertical perfecta */
    z-index: 10; /* Asegúrate de que estén por encima de otros elementos */
}

.left {
    left: 10px; /* Ajusta según sea necesario */
}

.right {
    right: 10px; /* Ajusta según sea necesario */
}
/* Clase para el LOGO que sale hacia la derecha */
.logo-slide-out-right {
    animation: slideOutRight 0.5s forwards; /* Aplica la animación de salida hacia la derecha */
}

@keyframes slideOutRight {
    0% {
        transform: translateX(0); /* Posición inicial */
        opacity: 1; /* Visible */
    }
    100% {
        transform: translateX(100%); /* Desplazarse fuera de la vista a la derecha */
        opacity: 0; /* Desvanecerse */
    }
}

/* Clase para mover los logos a la derecha */
.logo-slide-to-right {
    animation: slideToRight 0.5s forwards; /* Aplica la animación de desplazamiento */
}

@keyframes slideToRight {
    0% {
        transform: translateX(0); /* Posición inicial */
    }
    100% {
        transform: translateX(100%); /* Moverse a la derecha */
    }
}
/* Clase para el LOGO que sale hacia la izquierda */
.logo-slide-out-left {
    animation: slideOutLeft 0.5s forwards;
}

/* Clase para el LOGO que sale hacia la derecha */
.logo-slide-out-right {
    animation: slideOutRight 0.5s forwards;
}

/* Clase para mover los logos a la izquierda */
.logo-slide-to-left {
    animation: slideToLeft 0.5s forwards;
}

/* Clase para mover los logos a la derecha */
.logo-slide-to-right {
    animation: slideToRight 0.5s forwards;
}

/* Clase para mover los logos a la derecha */
.logo-slide-to-right2 {
    animation: slideToRight2 0.5s forwards;
}


@keyframes slideToRight2 {
    0% {
        transform: translateX(0);
        opacity: 1; /* Totalmente visible al inicio */
    }
    100% {
        transform: translateX(100%); /* Se mueve completamente a la derecha */
        opacity: 0; /* Se desvanece al final */
    }
}


/* Animación para el LOGO que aparece desde la izquierda */
.logo-enter {
    animation: slideInLeft 0.5s forwards; /* Nueva animación que deberías definir */
}

@keyframes slideInLeft {
    0% { transform: translateX(-100%); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
}

/* Animación para el LOGO que aparece desde la derecha */
.logo-enter {
    animation: slideInRight 0.5s forwards;
}

@keyframes slideInRight {
    0% { transform: translateX(100%); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
}


/* Clase para el LOGO que sale hacia la derecha */
.logo-slide-out-right {
    animation: slideOutRight 0.5s forwards; /* Aplica la animación de salida hacia la derecha */
}

@keyframes slideOutRight {
    0% {
        transform: translateX(0); /* Posición inicial */
        opacity: 1; /* Visible */
    }
    100% {
        transform: translateX(125%); /* Desplazarse fuera de la vista a la derecha */
        opacity: 0; /* Desvanecerse */
    }
}

/* Clase para mover los logos a la derecha */
.logo-slide-to-right {
    animation: slideToRight 0.5s forwards; /* Aplica la animación de desplazamiento */
}

@keyframes slideToRight {
    0% {
        transform: translateX(0); /* Posición inicial */
    }
    100% {
        transform: translateX(125%); /* Moverse a la derecha */
    }
}

/* Clase para el LOGO que sale hacia la izquierda */
.logo-slide-out-left {
    animation: slideOutLeft 0.5s forwards; /* Aplica la animación de salida hacia la izquierda */
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0); /* Posición inicial */
        opacity: 1; /* Visible */
    }
    100% {
        transform: translateX(-125%); /* Desplazarse fuera de la vista a la izquierda */
        opacity: 0; /* Desvanecerse */
    }
}

/* Clase para mover los logos a la izquierda */
.logo-slide-to-left {
    animation: slideToLeft 0.5s forwards; /* Aplica la animación de desplazamiento */
}

@keyframes slideToLeft {
    0% {
        transform: translateX(0); /* Posición inicial */
    }
    100% {
        transform: translateX(-125%); /* Moverse a la izquierda */
    }
}

/* Clase para el LOGO que aparece con un efecto de desvanecimiento */
.logo-fade-in {
    animation: fadeIn 0.5s forwards; /* Aplica la animación de desvanecimiento */
}

/* Definición de la animación de desvanecimiento */
@keyframes fadeIn {
    0% {
        opacity: 0; /* Comienza invisible */
    }
    100% {
        opacity: 1; /* Termina completamente visible */
    }
}

@media (max-width: 768px) {
    /* Asegurarse de que las animaciones estén desactivadas */
    .highlight-section-home {
        animation: none !important; /* Eliminar animaciones */
        visibility: visible !important; /* Asegurar que la sección esté visible */
    }

    /* Título de la sección */
    .highlight-title-home {
        font-size: 24px; /* Reducir el tamaño del título */
        padding: 8px 16px; /* Reducir el padding del título */
        margin-bottom: 40px; /* Reducir el margen inferior */
        margin-top: 0; /* Eliminar el margen superior negativo */
        text-align: center; /* Centrar el título */
    }

    /* Contenedor de las tarjetas */
    .highlight-cards-home {
        display: block; /* Asegurar que las tarjetas se apilen verticalmente */
        gap: 20px; /* Reducir el espacio entre tarjetas */
        margin-top: 20px; /* Ajustar el margen superior si es necesario */
    }

    .card-container-home {
        width: 100%; /* Hacer las tarjetas de 100% del ancho */
        max-width: 100%; /* Asegura que no se desborden */
        margin-bottom: 20px; /* Reducir el espacio entre tarjetas */
    }

    .card-home {
        font-size: 14px; /* Reducir el tamaño del texto dentro de las tarjetas */
        padding: 15px; /* Reducir el padding dentro de las tarjetas */
        text-align: center; /* Centrar el contenido dentro de la tarjeta */
        opacity: 1 !important; /* Asegura que las tarjetas tengan visibilidad plena */
    }

    .card-title-home {
        font-size: 20px; /* Reducir el tamaño del título dentro de las tarjetas */
    }

    .card-logo-home {
        height: 120px; /* Reducir el tamaño de los logos */
        width: auto; /* Mantener la proporción */
    }

    .card-text-home {
        font-size: 14px; /* Reducir el tamaño del texto en la descripción */
    }
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
    .highlight-cards-home {
        justify-content: center;
        margin-left: 2vh;
    }

    .card-container-home {
        width: 80%;  /* Ocupa el 80% del contenedor */
        margin: 10px 0;  /* Añadir margen vertical */
        justify-content: center;
    }

    .highlight-title-home {
        font-size: 1.5rem;
    }

    .card-text-home {
        font-size: 0.9rem;
    }
}

.discord-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    background-color: #030315;
    padding: 50px 20px;
    border-radius: 10px;
  }
  
  .discord-card {
    background-color: #0d083f;
    color: white;
    width: 18%;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
  }
  
  .discord-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .discord-name {
    font-size: 1.1em;
    font-weight: bold;
    color: #51cda2;
    margin-bottom: 10px;
  }
  
  .discord-quote {
    font-style: italic;
    color: white;
    font-size: 0.95em;
    line-height: 1.4;
  }
  
  