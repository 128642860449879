/* Estilo para la tabla */
.carrito-container {
    padding: 100px;
}

.carrito-title {
    text-align: center;
    font-size: 24px;
    margin-top: -60px;
    margin-bottom: 40px;
}

.carrito-table {
    width: 100%;
    border-collapse: collapse; /* Colapsa los bordes entre celdas */
    border-spacing: 0; /* Sin espaciado entre columnas */
}

.carrito-table th {
    background-color: #0d083f;
}

.carrito-table th, .carrito-table td {
    padding: 15px; /* Agrega padding dentro de las celdas */
    text-align: center;
    border: none; /* Elimina los bordes entre columnas */
    background-color: #5347cfc7;
    color: white;
}

.carrito-table th {
    font-weight: bold;
}

.carrito-row {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.carrito-row:hover {
    transform: translateY(-5px); /* Efecto de "levitación" al pasar el cursor */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Sombra para efecto de tarjeta */
}

/* Agregar borde solo entre las filas */
.carrito-table tr {
    border-bottom: 2px solid #645ad3c7; /* Línea entre las filas */
}

.carrito-table td img {
    width: 50px;
    height: auto;
    max-width: 100%;
}

.carrito-empty {
    text-align: center;
    font-style: italic;
    color: #888;
}

.game-image {
    max-width: 50px;
    max-height: 50px;
    object-fit: contain;
}

/* Título de la tabla */
.carrito-table-title {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
}

/* Contenedor general de las tablas */
.carrito-table-container {
    display: flex; /* Usamos flexbox para alinear las tablas en una fila */
    justify-content: space-between; /* Espacio entre las tablas */
    gap: 20px; /* Espacio entre las tablas */
    padding: 10px;
    flex-wrap: wrap; /* Permite que las tablas se muevan a la siguiente línea en pantallas más pequeñas */
}

/* Sección de cada tabla */
.carrito-table-section {
    flex: 1; /* Hace que cada tabla ocupe el mismo espacio disponible */
    min-width: 48%; /* Asegura que las tablas ocupen al menos el 48% del ancho */
    max-width: 48%; /* Limita el ancho máximo de cada tabla */
    overflow-x: auto; /* Permite el desplazamiento horizontal si el contenido es más grande */
}

/* Tabla de especificaciones */
.carrito-specifications-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Estilos para las celdas de la tabla */
.info-title {
    text-align: left;
    padding: 10px;
    background-color: #f4f4f4;
    font-weight: bold;
}

/* Celdas de datos */
.carrito-specifications-table td {
    padding: 10px;
    text-align: center;
}

/* Hacer que las tablas sean responsivas en pantallas pequeñas */
@media (max-width: 768px) {
    .carrito-table-section {
        min-width: 100%; /* Las tablas ocuparán el 100% en pantallas pequeñas */
        max-width: 100%;
    }

    .carrito-specifications-table td {
        padding: 8px;
        font-size: 14px;
    }
}




.add-to-cart-button:disabled {
    background-color: #b0f7e8; /* Color apagado para estado disabled */
    cursor: not-allowed;
    opacity: 0.7;
}

/* Estilos del modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #030315;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
    text-align: center;
}

.close-modal-btn {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.close-modal-btn:hover {
    background-color: #0056b3;
}

.carrito-table-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 10px;
    flex-wrap: wrap;
    overflow: auto; /* Permite el desplazamiento si el contenido es más grande */
}

.table-container2 {
    display: flex; /* Usa flexbox para mostrar las tablas una al lado de la otra */
    justify-content: space-between; /* Espacia las tablas */
    margin-top: 20px;
    overflow-x: auto; /* Habilita el desplazamiento horizontal si es necesario */
    width: 100%;
    margin-bottom: 30px;
}
/* Contenedor general de las tablas */
.table-container-2 {
    display: flex; /* Usa flexbox para alinear las tablas horizontalmente */
    justify-content: space-between; /* Añade espacio entre las tablas */
    gap: 20px; /* Espacio entre las tablas */
    padding: 10px;
    flex-wrap: wrap; /* Permite que las tablas se muevan a la siguiente línea si la pantalla es pequeña */
}

/* Sección de cada tabla */
.table-section-2 {
    flex: 1; /* Hace que cada tabla ocupe el mismo espacio disponible */
    min-width: 48%; /* Asegura que las tablas ocupen al menos el 48% del ancho */
    max-width: 48%; /* Limita el ancho máximo de cada tabla */
    overflow-x: auto; /* Permite desplazamiento horizontal si el contenido es más grande */
}

/* Estilo para las tablas */
.table-detalles {
    width: 100%; /* Asegura que la tabla ocupe todo el ancho disponible */
    border-collapse: collapse; /* Colapsa los bordes entre celdas */
    background-color: #0d083f; /* Fondo blanco para las tablas */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Añade sombra a las tablas */
    margin-bottom: 20px;
}

/* Estilo de las celdas de la tabla */
.info-title {
    text-align: left;
    padding: 10px;
    background-color: #f4f4f4;
    font-weight: bold;
    color: white;
}

/* Celdas de datos */
table td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd; /* Líneas divisorias entre las filas */
    color: white;
}

/* Título de la tabla */
.table-title {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 20px;
}

/* Estilos responsivos: en pantallas pequeñas las tablas ocuparán el 100% del ancho */
@media (max-width: 768px) {
    .table-section-2 {
        min-width: 100%; /* Las tablas ocuparán el 100% en pantallas pequeñas */
        max-width: 100%;
    }
}

.ver-detalles-btn{
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #51cda2;
    font-size: 15px;
}

.ver-detalles-btn:hover{
    background-color: #2d8566;
}

.svg-icon-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.svg-icon-btn svg {
  width: 30px;
  height: 30px;
}

.total-container {
    display: flex;
    flex-direction: column;  /* Cambiar la dirección a columna */
    align-items: flex-end;   /* Alinea todo a la derecha */
    margin-top: 20px;
    padding: 10px;
}

.total-box {
    background-color: #f8f8f8;
    padding: 0px 40px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: right;  /* Alinea el contenido dentro del recuadro a la derecha */
}

.proceed-btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;  /* Alinea el botón a la derecha */
    margin-top: 10px;  /* Espacio entre el recuadro y el botón */
}

.proceed-btn {
    background-color: #51cda2;
    color: #030315;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
}

.proceed-btn:hover {
    background-color: #3ea686;
}

.boost-table-title {
    text-align: center;
    color: #51cda2;
    font-size: 24px;
    margin-bottom: 20px;
}

.boost-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.boost-table-section {
    max-width: 80%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.boost-description {
    text-align: center;
    color: #51cda2;
    font-size: 18px;
    line-height: 1.6;
}

.close-modal-btn2 {
    background-color: #51cda2;  /* Color verde */
    color: rgb(0, 0, 0);
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;  /* Ocupa todo el ancho del modal */
    font-size: 20px;
    text-align: center;  /* Centra el texto dentro del botón */
    margin-top: 20px;  /* Separación con los elementos de arriba */
    font-weight: 600;
}

.close-modal-btn2:hover {
    background-color: #3ea686;  /* Cambia el color al pasar el mouse */
}

.account-info p span{
    color: white;
}

.proceed-btn:disabled {
    cursor: not-allowed;  /* Makes the cursor look like it's disabled */
    opacity: 0.5;          /* Reduce opacity to indicate it's disabled */
    background-color: #ddd;  /* Light gray background when disabled */
}

/* Optional: Custom class for when the button is disabled (if needed) */
.disabled-btn {
    background-color: #ddd; /* Change background color to light gray */
    color: #888;            /* Dim the text color */
}

/* Estilos para pantallas móviles */
@media screen and (max-width: 768px) {
    .carrito-container {
        margin-top: 10px;
        padding: 10px;
    }

    .carrito-title {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 15px;
    }

    .carrito-table {
        width: 100%;
        border-collapse: collapse;
        font-size: 0.9rem;
    }

    .carrito-table th, .carrito-table td {
        padding: 8px 5px;
        text-align: center;
        word-break: break-word;
    }

    .carrito-row img {
        width: 50px;
        height: auto;
        margin: 0 auto;
    }

    .game-image {
        max-width: 100%;
        height: auto;
    }

    .total-container {
        margin-top: 20px;
        text-align: center;
    }

    .total-box {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    .proceed-btn-container {
        margin-top: 10px;
    }

    .proceed-btn {
        padding: 10px 20px;
        font-size: 1rem;
        background-color: #51cda2;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .proceed-btn.disabled-btn {
        background-color: #ccc;
        cursor: not-allowed;
    }

    /* Modal para móviles */
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        padding: 10px;
    }

    .modal-content {
        background-color: #000000;
        width: 90%;
        max-height: 80%;
        overflow-y: auto;
        border-radius: 10px;
        padding: 20px;
        text-align: left;
    }

    .close-modal-btn2 {
        display: block;
        margin: 20px auto 0;
        padding: 10px 15px;
        font-size: 1rem;
        background-color: #51cda2;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-button {
    background-color: #444;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-button:disabled {
    background-color: #666;
    cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
    background-color: #75FACC;
    color: #030315;
}

.pagination-info {
    font-size: 1rem;
    color: white;
    margin: 0 10px;
}
