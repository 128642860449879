@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    background-color: #03031F;
    margin: 0;
}

.mi-cuenta-container {
    width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #645ad3c7;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    color: #51cda2;
}

.mi-cuenta-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #75FACC;
}

.account-info {
    font-size: 18px;
    color: #75FACC;
    text-align: center;
}

.error-message {
    color: #f15b5b;
    margin-bottom: 20px;
}

.profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin: 20px auto;
    display: block;
    border: 3px solid #51cda2;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

/* Botones */
.mi-cuenta-edit-button,
.mi-cuenta-accept-button,
.mi-cuenta-cancel-button,
.mi-cuenta-delete-button {
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin: 10px 5px;
    transition: background-color 0.3s ease;
}

.mi-cuenta-edit-button {
    background-color: #51cda2;
    color: #0d083f;
}

.mi-cuenta-edit-button:hover {
    background-color: #215f49;
}

.mi-cuenta-accept-button {
    background-color: #51cda2;
    color: #0d083f;
}

.mi-cuenta-accept-button:hover {
    background-color: #75FACC;
}

.mi-cuenta-cancel-button {
    background-color: #3c3cc5;
    color: #0d083f;
}

.mi-cuenta-cancel-button:hover {
    background-color: #4949ea;
}

.mi-cuenta-delete-button {
    background-color: #e74c3c;
    color: #0d083f;
}

.mi-cuenta-delete-button:hover {
    background-color: #c0392b;
}

/* Ajustes responsivos */
@media (max-width: 600px) {
    .mi-cuenta-container {
        padding: 15px;
    }
    
    .mi-cuenta-title {
        font-size: 20px;
    }

    .profile-image {
        width: 100px;
        height: 100px;
    }
}

/* Responsive */
@media (max-width: 480px) {
    .mi-cuenta-container {
        width: 90%; /* Ajusta el ancho al 95% en móviles */
        padding: 15px; /* Reduce el padding para que quede más compacto */
    }

    .mi-cuenta-title {
        font-size: 22px; /* Reduce el tamaño del título para pantallas pequeñas */
    }

    .account-info p {
        font-size: 14px; /* Disminuye el tamaño de fuente del párrafo */
    }

    .account-info input {
        font-size: 14px; /* Asegura que los campos de entrada sean legibles */
    }

    .mi-cuenta-edit-button,
    .mi-cuenta-accept-button,
    .mi-cuenta-cancel-button,
    .mi-cuenta-delete-button {
        padding: 10px;
        font-size: 14px; /* Ajusta el tamaño de los botones */
    }
}

.profile-image-container {
    position: relative;
    display: inline-block;
}

.edit-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    font-size: 16px;
}

.profile-image-container {
    position: relative; /* Necesario para posicionar el lápiz */
    display: inline-block;
}

.edit-icon {
    position: absolute; /* Posiciona el lápiz sobre la imagen */
    bottom: 10px; /* Ajusta según el diseño */
    right: 10px;
    background: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: none; /* Oculto por defecto */
}

.profile-image-container:hover .edit-icon {
    display: block; /* Aparece al pasar el mouse */
}

.profile-image-container {
    position: relative; /* Necesario para posicionar el lápiz */
    display: inline-block;
}

.edit-icon {
    position: absolute; /* Posiciona el lápiz sobre la imagen */
    bottom: 10px; /* Ajusta según el diseño */
    right: 10px;
    background: white;
    border-radius: 50%;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: block; /* Siempre visible */
    color: #03031F;
}

.profile-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}

.profile-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    width: 90%; /* Ajusta el ancho según sea necesario */
    max-width: 400px;
}

.profile-modal-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px; /* Espaciado entre imágenes */
}

.profile-modal-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease;
    object-fit: cover;
}

.profile-modal-image:hover {
    transform: scale(1.1); /* Efecto de zoom al pasar el mouse */
}

.profile-modal-close {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3c3cc5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.profile-modal-close:hover {
    background-color: #1f1f79;
}
