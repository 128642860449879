/* src/styles/ventaow.css */
.ventaow-container {
    padding: 20px;
    text-align: center;
}

.ventaow-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.open-modal-button {
    padding: 10px 20px;
    background-color: #007bff; /* Color del botón */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    margin: auto;
    max-width: 600px;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.close-modal-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #dc3545; /* Color del botón de cerrar */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.ventas-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.ventas-table th,
.ventas-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.ventas-table th {
    background-color: #645AD3;
}

.modal {
    position: relative;
    margin: auto;
    padding: 20px;
    width: 80%;
    max-width: 500px; /* Ancho máximo del modal */
    max-height: 80vh; /* Altura máxima del modal */
    background-color: #ffffff; /* Color de fondo blanco */
    border-radius: 10px; /* Bordes redondeados */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Sombra suave */
    overflow-y: auto; /* Permitir desplazamiento vertical */
}

.overlay {
    background: rgba(0, 0, 0, 0.7); /* Fondo oscuro semi-transparente */
}

.modal-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333; /* Color del título */
}

.ventaow-form {
    display: flex;
    flex-direction: column; /* Disposición en columna */
}

.ventaow-label {
    font-weight: bold;
    margin-bottom: 5px; /* Espaciado debajo de la etiqueta */
}

.ventaow-input {
    padding: 10px; /* Relleno interno */
    margin-bottom: 15px; /* Espaciado entre campos */
    border: 1px solid #ccc; /* Borde ligero */
    border-radius: 5px; /* Bordes redondeados */
    font-size: 16px; /* Tamaño de fuente */
    transition: border-color 0.3s; /* Transición suave al cambiar color */
}

.ventaow-input:focus {
    border-color: #007BFF; /* Color del borde al enfocar */
    outline: none; /* Sin contorno */
}

.password-container {
    display: flex;
    align-items: center; /* Alinear verticalmente */
}

.password-container button {
    background: none; /* Sin fondo */
    border: none; /* Sin borde */
    cursor: pointer; /* Cambiar el cursor a pointer */
    font-size: 18px; /* Tamaño de fuente */
    margin-left: 10px; /* Espaciado a la izquierda */
}

.ventaow-button {
    padding: 10px;
    background-color: #007BFF; /* Color de fondo azul */
    color: white; /* Color del texto */
    border: none; /* Sin borde */
    border-radius: 5px; /* Bordes redondeados */
    font-size: 16px; /* Tamaño de fuente */
    cursor: pointer; /* Cambiar el cursor a pointer */
    transition: background-color 0.3s; /* Transición suave al cambiar color */
}

.ventaow-button:hover {
    background-color: #0056b3; /* Color más oscuro al pasar el mouse */
}

.close-modal-button {
    padding: 10px;
    background-color: #dc3545; /* Color de fondo rojo */
    color: white; /* Color del texto */
    border: none; /* Sin borde */
    border-radius: 5px; /* Bordes redondeados */
    cursor: pointer; /* Cambiar el cursor a pointer */
    transition: background-color 0.3s; /* Transición suave al cambiar color */
    display: block; /* Hacer que el botón ocupe todo el ancho */
    margin: 20px auto 0; /* Centrando el botón con margen superior */
}

.close-modal-button:hover {
    background-color: #c82333; /* Color más oscuro al pasar el mouse */
}

.filter-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    gap: 15px; /* Espaciado entre los checkboxes */
    align-items: center; /* Alinear verticalmente */
}

.filter-container label {
    display: flex;
    align-items: center;
    gap: 5px; /* Espaciado entre checkbox y texto */
    font-weight: bold;
    cursor: pointer;
    color: white; /* Letras blancas */
}

.filter-container input[type="checkbox"] {
    transform: scale(1.2); /* Tamaño del checkbox */
    accent-color: #007bff; /* Color del checkbox */
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-button {
    background-color: #51cda2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
    background-color: #45b594;
}

.pagination-info {
    font-size: 16px;
    margin: 0 10px;
    color: #333;
}

/* Estilos generales para móviles */
@media (max-width: 768px) {
    .ventaow-container {
        padding: 10px; /* Reduce el padding general */
    }

    .ventaow-title, 
    .ventas-title {
        font-size: 1.5rem; /* Ajusta el tamaño del texto */
        text-align: center;
        margin-bottom: 10px; /* Reduce márgenes inferiores */
    }

    .ventaow-form {
        padding: 0; /* Elimina el padding extra */
    }

    .ventaow-label {
        font-size: 1rem; /* Reduce el tamaño de las etiquetas */
    }

    .ventaow-input {
        font-size: 1rem;
        padding: 8px; /* Asegura un tamaño adecuado */
    }

    .ventaow-button, 
    .close-modal-button {
        font-size: 1rem;
        padding: 10px;
        width: 100%; /* Botones adaptados al ancho completo */
        margin-bottom: 10px; /* Espaciado entre botones */
    }

    .filter-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center; /* Centra los filtros */
        padding: 10px 0;
    }

    /* Ajustes específicos para la tabla */
    .ventas-table {
        width: 100%; /* Asegura que la tabla ocupe todo el ancho */
        overflow-x: auto; /* Permite scroll horizontal si es necesario */
        display: block; /* Convierte la tabla en un bloque para adaptarse */
    }

    .ventas-table th,
    .ventas-table td {
        font-size: 0.9rem; /* Reduce el tamaño del texto */
        padding: 8px; /* Reduce el padding */
        text-align: center; /* Centra el contenido */
        white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    }

    /* Ajustes para filas */
    .ventas-table tbody tr {
        display: flex;
        flex-direction: column; /* Cada fila será un bloque en dispositivos pequeños */
        border-bottom: 1px solid #ccc; /* Añade una separación entre filas */
        padding-bottom: 10px;
    }

    .ventas-table thead {
        display: none; /* Oculta los encabezados en móvil */
    }

    .ventas-table tbody tr td {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        border-bottom: none;
    }

    .ventas-table tbody tr td:before {
        content: attr(data-label); /* Usa data-label para mostrar encabezados */
        font-weight: bold;
        text-transform: uppercase;
        margin-right: 10px;
    }

    .pagination-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        font-size: 0.9rem;
        margin-top: 10px;
    }

    .pagination-button {
        padding: 8px 15px;
        font-size: 0.9rem;
        border-radius: 5px;
    }
}

@media (max-width: 768px) {
    /* Contenedor general */
    .ventas-table {
        width: 100%; /* Asegura que ocupe todo el ancho del contenedor */
        margin: 0 auto; /* Centra la tabla horizontalmente */
        overflow-x: auto; /* Permite desplazamiento horizontal si es necesario */
        display: block; /* Convierte la tabla en un bloque */
    }

    .ventas-table tbody {
        display: flex;
        flex-direction: column; /* Organiza las filas en una columna */
        align-items: center; /* Centra las filas horizontalmente */
    }

    .ventas-table tbody tr {
        display: flex;
        flex-direction: column;
        width: 100%; /* Asegura que cada fila ocupe todo el ancho disponible */
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
        align-items: center; /* Centra cada fila horizontalmente */
    }

    /* Opcional: centrado de las celdas */
    .ventas-table tbody tr td {
        text-align: center; /* Centra el texto dentro de las celdas */
    }

    .ventas-table thead {
        display: none; /* Oculta los encabezados para una mejor visualización en móvil */
    }
}


.ventas-table-container {
    display: flex;
    justify-content: center;
    align-items: center; /* Centrar verticalmente si es necesario */
    width: 100%;
    margin: 40px 0; /* Espaciado superior e inferior */
    padding: 0 20px; /* Asegura espacio lateral en pantallas pequeñas */
}

.ventas-table {
    width: 90%; /* Cambiar al 80% del contenedor */
    max-width: 1200px; /* Limitar el ancho máximo */
    background-color: #1A1A2E; /* Fondo oscuro pero elegante */
    border-collapse: collapse;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    color: #FFFFFF; /* Texto blanco */
    margin: 0 auto; /* Márgenes automáticos para centrar */
}

.ventas-table thead {
    background-color: #645AD3; /* Morado para el encabezado */
}

.ventas-table th, .ventas-table td {
    padding: 15px 20px;
    text-align: left;
    border-bottom: 1px solid #343450;
}

.ventas-table th {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #FFFFFF; /* Texto blanco */
}

.ventas-table tbody tr {
    transition: background-color 0.3s ease;
}

.ventas-table tbody tr:hover {
    background-color: #4B4B82; /* Resalta con un morado más oscuro */
}

.ventas-table tbody tr:nth-child(even) {
    background-color: #2C2C44; /* Alternar filas con un tono más claro */
}

.ventas-table td {
    font-size: 0.95rem;
    color: #75FACC; /* Verde agua para el texto */
}

.ventas-table td button {
    padding: 8px 6px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
    color: #FFFFFF; /* Texto blanco */
}

.ventas-table td button:first-child {
    background-color: #75FACC; /* Botón verde agua */
}

.ventas-table td button:first-child:hover {
    background-color: #59D3B0; /* Hover en verde más oscuro */
}

.ventas-table td button:last-child {
    background-color: #E74C3C; /* Botón rojo */
    margin-left: 5px;
}

.ventas-table td button:last-child:hover {
    background-color: #C0392B; /* Hover en rojo más oscuro */
}
