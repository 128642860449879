/* src/components/faq.css */

/* Estilo general para el contenedor FAQ */
.faq {
    padding: 20px 200px;  /* Agregar margen adicional a los lados */
    box-sizing: border-box;  /* Asegura que el padding no afecte el tamaño del contenedor */
    width: 100%;  /* Ocupa todo el ancho disponible */
    margin-bottom: 20px;
}

/* Los estilos para los ítems de las preguntas frecuentes */
.faq-item {
    margin: 10px 100px;
}

/* Estilo para las preguntas (FAQ) */
.faq-question {
    background-color: #645ad3c7;  /* Fondo morado */
    padding: 15px;  /* Relleno interno */
    font-size: 20px;  /* Tamaño de fuente para la pregunta */
    display: flex;  /* Flexbox para organizar contenido */
    align-items: center;  /* Alinea verticalmente los elementos */
    justify-content: space-between;  /* Espacio entre la pregunta y la flecha */
    cursor: pointer;
    border-radius: 5px;  /* Bordes redondeados */
    color: white;  /* Texto blanco */
    font-weight: 600;  /* Peso de fuente más pesado */
}

/* Estilo para las respuestas */
.faq-answer {
    padding: 0 15px;  /* Espaciado lateral */
    font-size: 20px;  /* Tamaño de fuente para la respuesta */
    color: white;  /* Texto blanco */
    max-height: 0;  /* Comienza con altura 0 */
    overflow: hidden;  /* Oculta el contenido extra */
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;  /* Transición suave */
}

/* Activar la visualización de la respuesta */
.faq-answer.active {
    max-height: 2000px;  /* Altura suficiente para la respuesta */
    padding: 15px;  /* Relleno para la respuesta */
}

/* Estilo para la flecha */
.faq-arrow {
    transition: transform 0.3s ease;  /* Transición suave para el giro de la flecha */
}

.faq-arrow.expanded {
    transform: rotate(180deg);  /* Flecha rotada cuando la respuesta está expandida */
}

/* Estilo del título h1 */
.faq h1 {
    color: white;  /* Texto blanco */
    text-align: center;  /* Centrado */
    margin: 10px 0;  /* Márgenes reducidos */
}

/* Estilos para mobile */
@media (max-width: 480px) {
    /* El contenedor principal toma todo el ancho de la pantalla */
    .faq {
        padding: 10px;  /* Reducir padding lateral */
        margin: 0;  /* Eliminar márgenes */
    }

    /* Título de las preguntas frecuentes */
    h1 {
        font-size: 22px;  /* Reducir tamaño de fuente */
        text-align: center;  /* Centrar el título */
        margin: 10px 0;  /* Márgenes reducidos */
    }

    /* Estilos para cada ítem */
    .faq-item {
        width: 100%;  /* Cada ítem ocupa todo el ancho */
        margin: 10px 0;  /* Espaciado entre ítems */
        padding: 10px;  /* Relleno interno */
        border: 1px solid #ddd;  /* Bordes suaves */
        border-radius: 5px;  /* Bordes redondeados */
        background-color: #f9f9f9;  /* Fondo suave para cada pregunta */
        box-sizing: border-box;  /* Asegura que el padding no afecte el ancho */
    }

    /* Estilo para las preguntas */
    .faq-question {
        font-size: 16px;  /* Reducir tamaño de fuente */
        font-weight: bold;  /* Mantener la negrita */
        cursor: pointer;
        display: flex;  /* Flexbox para organizar contenido */
        justify-content: space-between;  /* Espacio entre la pregunta y la flecha */
        align-items: center;
        background-color: #645ad3c7;  /* Fondo morado */
        padding: 15px;  /* Relleno interno */
        color: white;  /* Texto blanco */
        border-radius: 5px;  /* Bordes redondeados */
    }

    /* Estilo de la flecha */
    .faq-arrow {
        font-size: 18px;  /* Tamaño adecuado de la flecha */
        transition: transform 0.3s ease;  /* Transición para el giro */
    }

    .faq-arrow.expanded {
        transform: rotate(180deg);  /* Flecha hacia abajo cuando se expanda */
    }

    /* Estilo de la respuesta */
    .faq-answer {
        font-size: 14px;  /* Reducir tamaño de fuente */
        margin-top: 10px;  /* Espacio entre la pregunta y la respuesta */
        color: #333;  /* Color de texto más oscuro */
        display: none;  /* Inicialmente oculto */
    }

    /* Mostrar la respuesta cuando se expande */
    .faq-answer.active {
        display: block;  /* Mostrar respuesta */
    }

    /* Evitar desbordamiento de texto */
    .faq-answer p {
        word-wrap: break-word;  /* Asegurar que el texto se ajuste */
    }
}

@media (max-width: 768px) {
    .discord-section {
      flex-direction: column; /* Las tarjetas se apilan una encima de otra */
      align-items: center; /* Centra las tarjetas */
      padding: 10px;
    }
  
    .discord-card {
      width: 90%; /* Las tarjetas ocupan más ancho en pantallas pequeñas */
      margin-bottom: 20px; /* Espaciado entre las tarjetas */
    }
  }