/* Asegúrate de que el contenedor principal ocupe toda la altura de la ventana */
html, body {
    height: 100% !important; /* Esto asegura que el body ocupe toda la altura */
    margin: 0 !important; /* Elimina márgenes por defecto */
    padding: 0 !important; /* Elimina padding por defecto */
}

/* Contenedor principal para distribuir el contenido */
.main-container {
    display: flex !important;
    flex-direction: column !important; /* Coloca los elementos en columna, uno debajo del otro */
    min-height: 100vh !important; /* Asegura que el contenedor principal ocupe toda la altura de la ventana */
}

/* El contenido debe ocupar el espacio disponible y empujar el footer al fondo si es necesario */
.content {
    flex: 1 !important; /* Esto hace que el contenido se expanda para ocupar el espacio disponible */
}

/* Estilo del footer */
.footer {
    margin-top: auto !important; /* Esto empuja el footer hacia abajo si el contenido es más pequeño */
}

/* Estilos generales */
.footer {
    background-color: #0d083f !important; /* Fondo oscuro */
    color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Distribuye los elementos */
    gap: 20px; /* Espacio entre los elementos */
    border-bottom: 1px solid #515151; /* Línea gris */

}

/* Contenedor superior con logo y texto */
.footer-top {
    display: flex;
    align-items: center; /* Centra los elementos verticalmente */
    justify-content: flex-start; /* Alinea los elementos a la izquierda */
    gap: 20px; /* Espacio entre los elementos */
    border-bottom: 1px solid #515151; /* Línea gris */
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-left: 20px; /* Eliminar el margen izquierdo excesivo */
    width: 30%; /* Asegura que el contenedor ocupe todo el ancho */
    flex-wrap: nowrap; /* Evita que los elementos se envuelvan */
}


.footer-logo-img {
    width: 120px;
    height: auto;
}

.footer-divider {
    height: 30px;
    border-left: 1px solid #515151;
}

.footer-right {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: white;
}

.footer-right-text {
    font-size: 14px;
    margin: 0;
}

/* Contenedor inferior con disclaimer */
/* Contenedor inferior con disclaimer */


/* Contenedor inferior con disclaimer */
.footer-bottom {
    display: flex;
    align-items: center; /* Centra el contenido verticalmente */
    justify-content: space-between; /* Asegura que haya espacio entre los elementos */
    color: #a6a6a6; /* Gris claro */
    font-size: 12px;
    line-height: 1.6;
    margin-top: 20px;
    width: 100%; /* Asegura que ocupe todo el ancho disponible */
    margin: 0 50px;
}

/* Contenedor de disclaimer */
.footer-disclaimer {
    width: 10%; /* Asegura que ocupe todo el ancho disponible */
    text-align: center; /* Centra el texto dentro del disclaimer */
    margin: 0; /* Elimina márgenes */
    padding: 10px 0; /* Añade un poco de espacio arriba y abajo */
    flex: 1; /* Hace que el disclaimer ocupe todo el espacio disponible */
    margin-right: 100px;
}

/* Créditos */
.footer-credits {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #a6a6a6; /* Gris claro */
    margin-right: 40px;
    font-size: 16px;
}

.footer-instagram {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
}

.footer-instagram:hover {
    color: #fc41da;
    text-decoration: underline;
}

/* Contenedor de enlaces en el pie de página */
.footer-links {
    display: flex;
    flex-direction: column; /* Apila los elementos de arriba hacia abajo */
    gap: 10px; /* Espacio entre los enlaces */
    align-items: center; /* Centra los enlaces horizontalmente */
    margin: 0;
    margin-right: 100px;
    font-size: 40px ;
}

/* Estilos para los enlaces */
.footer-link {
    color: #ffffff; /* Color blanco para los enlaces */
    text-decoration: none; /* Elimina subrayado */
    font-size: 16px; /* Tamaño de fuente para los enlaces */
}

.footer-link:hover {
    text-decoration: underline; /* Subraya al pasar el ratón */
}

.footer-link h3{
    color: #ffffff; /* Color blanco para los enlaces */
    text-decoration: none; /* Elimina subrayado */
    font-size: 14px; /* Tamaño de fuente para los enlaces */
    margin-bottom: -200px !important;
}

/* Estilos solo para móviles (pantallas con un ancho máximo de 768px) */
@media (max-width: 768px) {
    .footer {
        padding: 20px;
        display: flex;
        flex-direction: column; /* Apila todo el contenido verticalmente */
        align-items: center; /* Centra los elementos horizontalmente */
        gap: 20px; /* Espacio entre los elementos */
        width: 100%;
        margin: 0; /* Elimina márgenes del footer */
    }

    /* Contenedor superior con logo y texto */
    .footer-top {
        display: flex;
        flex-direction: column; /* Apila los elementos verticalmente */
        align-items: center; /* Centra los elementos */
        gap: 10px; /* Espacio entre los elementos */
        width: 100%; /* Asegura que ocupe todo el ancho disponible */
        padding: 0; /* Elimina el padding */
        margin: 0; /* Elimina los márgenes */
    }

    .footer-logo-img {
        width: 80px; /* Ajusta el tamaño del logo para móviles */
        height: auto;
    }

    /* Divider ya no se muestra en móviles */
    .footer-divider {
        display: none;
    }

    /* Contenedor de los textos de la derecha */
    .footer-right {
        display: flex;
        flex-direction: column; /* Apila los textos verticalmente */
        gap: 5px; /* Espacio entre los textos */
        align-items: center; /* Centra los textos */
        color: white;
        margin: 0; /* Elimina los márgenes */
        margin-bottom: 20px;
    }

    .footer-right-text {
        font-size: 14px; /* Ajusta el tamaño de la fuente para móviles */
    }

    /* Contenedor inferior con disclaimer */
    .footer-bottom {
        display: flex;
        flex-direction: column; /* Apila todo verticalmente */
        align-items: center; /* Centra los elementos */
        justify-content: center;
        text-align: center; /* Centra el texto */
        color: #a6a6a6;
        font-size: 12px;
        line-height: 1.6;
        margin-top: 0; /* Elimina el margen superior */
        margin: 0; /* Elimina los márgenes */
        width: 100%; /* Asegura que ocupe todo el ancho disponible */
    }

    /* Contenedor de disclaimer */
    .footer-disclaimer {
        width: 90%; /* Ocupa el 90% del ancho */
        text-align: center; /* Centra el texto dentro del disclaimer */
        margin: 0; /* Elimina márgenes */
        padding: 10px 0;
        margin-right: 10px;
    }

    /* Créditos */
    .footer-credits {
        display: flex;
        flex-direction: column; /* Apila los créditos verticalmente */
        align-items: center; /* Centra los elementos */
        gap: 10px;
        color: #a6a6a6;
        margin: 0; /* Elimina los márgenes */

    }

    .footer-instagram {
        margin-top: -20px;
        text-decoration: none;
    }

    .footer-instagram:hover {
        text-decoration: underline;
    }

    /* Contenedor de enlaces en el pie de página */
    .footer-links {
        display: flex;
        flex-direction: column; /* Apila los enlaces verticalmente */
        gap: 10px; /* Espacio entre los enlaces */
        align-items: center; /* Centra los enlaces horizontalmente */
        margin: 0; /* Elimina márgenes */
    }

    /* Estilos para los enlaces */
    .footer-link {
        color: #ffffff; /* Color blanco para los enlaces */
        text-decoration: none; /* Elimina subrayado */
        font-size: 14px; /* Tamaño de fuente para los enlaces */
    }

    .footer-link:hover {
        text-decoration: underline; /* Subraya al pasar el ratón */
    }
}
