@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
    background-color: #03031F;
    margin: 0; /* Asegúrate de que no haya margen en el body */
    font-family: 'Poppins', sans-serif;
}


.ventas-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centra las tarjetas */
    padding: 10px 100px; /* Agrega espacio a la izquierda y a la derecha del contenedor */
}

.card {
    border: 1px solid #322839; /* Bordes opcionales para las tarjetas */
    border-radius: 8px;
    margin: 10px; /* Espaciado entre tarjetas */
    width: calc(22% - 20px); /* Ancho para mostrar 4 tarjetas por fila, restando márgenes */
    text-align: center; /* Centra el texto dentro de las tarjetas */
    transition: transform 0.2s; /* Transición suave para el hover */
    background-color: #0d083f;
    color: #51cda2;
    display: flex; /* Usa flexbox para la tarjeta */
    flex-direction: column; /* Coloca los elementos en columna */
    height: 300px; /* Altura fija para las tarjetas */
    overflow: hidden; /* Evita que el contenido sobresalga */
}

.card:hover {
    transform: scale(1.05); /* Aumenta ligeramente la tarjeta al pasar el mouse */
}

.card-image {
    width: 100%; /* Ocupa todo el ancho de la tarjeta */
    height: 140px; /* Altura fija para las imágenes */
    object-fit: cover; /* Ajusta la imagen para cubrir el área */
    border-radius: 5px; /* Bordes redondeados para las imágenes */
}

.card-info {
    display: flex; /* Usa flexbox para alinear los elementos */
    flex-direction: column; /* Coloca los elementos en columna */
    justify-content: space-between; /* Distribuye el espacio entre el precio y el botón */
    flex-grow: 1; /* Permite que este contenedor crezca para ocupar el espacio restante */
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.view-account-button {
    background-color: #51cda2; /* Color de fondo */
    color: #0d083f; /* Color del texto */
    border: none; /* Sin borde */
    border-radius: 5px; /* Bordes redondeados */
    padding: 10px 25px; /* Espaciado interno */
    cursor: pointer; /* Cambia el cursor al pasar por encima */
    transition: background-color 0.3s; /* Transición suave para el color de fondo */
    margin-top: auto; /* Empuja el botón hacia abajo */
    margin-bottom: 10px; /* Espaciado inferior para el botón */
}

.view-account-button:hover {
    background-color: #3e9e7d; /* Cambia el color al pasar el mouse */
}

.card-info p{
    font-size: 18px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ocupa toda la pantalla */
}

.spinner {
    border: 8px solid #f3f3f3; /* Bordes claros */
    border-top: 8px solid #3498db; /* Borde azul para animación */
    border-radius: 50%; /* Forma circular */
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@media (max-width: 480px) {
    .ventas-container {
        padding: 10px; /* Reducimos el padding del contenedor para dar más espacio */
    }

    .card {
        width: calc(100% - 20px); /* Ajusta el ancho de la tarjeta para que ocupe más espacio */
        height: 350px; /* Mantén la altura un poco más grande si lo deseas */
    }

    .card-image {
        height: 160px; /* Aumenta la altura de las imágenes para que quede más proporcional */
    }

    .card-info p {
        font-size: 20px; /* Aumenta el tamaño del texto para mejor legibilidad */
    }

    .view-account-button {
        padding: 12px 30px; /* Aumenta el tamaño del botón para hacerlo más accesible */
    }
}


.filter-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
    margin-top: 20px;
}

.filter-container label {
    display: flex;
    align-items: center;
    background-color: #191960;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.filter-container label:hover {
    background-color: #51cda2;
}

.filter-container input {
    margin-right: 0.5rem;
}
